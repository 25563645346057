import SponsorBannerPageA from './constants/page-a.png';
import SponsorBannerPageB from './constants/page-a.png';
import countriesIsoCodesJson from './constants/countries-iso-codes.json';
import timezonesJson from './constants/timezones.json';
import LinkMatcherConstants from './constants/linkMatcher';

const timezones = Object.values(timezonesJson).map((x) => x.name);

const alwaysActiveEmailTemplatesTriggers = [
  'Registration',
  'User account creation',
  'Forgot password',
  'recover_usernames',
];

const notEditableEmailTemplatesParams = [
  'survey_response',
  'Registration',
  'User account creation',
  'Forgot password',
  'recover_usernames',
];

const BillingFlowTypes = {
  SPONSOR: {
    id: 'sponsor',
    label: (intl) =>
      intl.formatMessage({
        description: 'billing flow sponsor',
        defaultMessage: 'Sponsor',
      }),
  },
  PARTICIPATION: {
    id: 'participation',
    label: (intl) =>
      intl.formatMessage({
        description: 'billing flow participation',
        defaultMessage: 'Participation',
      }),
  },
};

const BillingStatuses = {
  UNPROCESSED: {
    id: 'unprocessed',
    label: (intl) =>
      intl.formatMessage({
        description: 'billing status unprocessed',
        defaultMessage: 'Unprocessed',
      }),
  },
  TO_BE_PROCESSED: {
    id: 'toBeProcessed',
    label: (intl) =>
      intl.formatMessage({
        description: 'billing status to be processed',
        defaultMessage: 'To be processed',
      }),
  },
  PROCESSED: {
    id: 'processed',
    label: (intl) =>
      intl.formatMessage({
        description: 'billing status processed',
        defaultMessage: 'Processed',
      }),
  },
};

const AllotmentChannels = {
  WEB: {
    id: 'web',
    label: (intl) =>
      intl.formatMessage({
        description: 'allotment web channel in channelview label',
        defaultMessage: 'Web',
      }),
  },
  PAX: {
    id: 'pax',
    label: (intl) =>
      intl.formatMessage({
        description: 'allotment participation channel in channelview label',
        defaultMessage: 'Participants',
      }),
  },
  SPEAKER: {
    id: 'speaker',
    label: (intl) =>
      intl.formatMessage({
        description: 'allotment faculty channel in channelview label',
        defaultMessage: 'Faculty',
      }),
  },
  OTHER: {
    id: 'other',
    label: (intl) =>
      intl.formatMessage({
        description: 'allotment other channel in channelview label',
        defaultMessage: 'Others',
      }),
  },
  DELEGATION: {
    id: 'delegation',
    label: (intl) =>
      intl.formatMessage({
        description: 'allotment delegation channel in channelview label',
        defaultMessage: 'Delegations',
      }),
  },
  SPONSOR: {
    id: 'sponsor',
    label: (intl) =>
      intl.formatMessage({
        description: 'allotment sponsor channel in channelview label',
        defaultMessage: 'Sponsors',
      }),
  },
  AGENCYGROUP: {
    id: 'agencygroup',
    label: (intl) =>
      intl.formatMessage({
        description: 'allotment agencies groups channel in channelview label',
        defaultMessage: 'Agencies',
      }),
  },
};

const GroupStatuses = {
  OPEN: {
    id: 'open',
    label: (intl) =>
      intl.formatMessage({
        description: 'group status open',
        defaultMessage: 'Open',
      }),
  },
  CLOSED: {
    id: 'closed',
    label: (intl) =>
      intl.formatMessage({
        description: 'group status closed',
        defaultMessage: 'Closed',
      }),
  },
  CANCELLED: {
    id: 'cancelled',
    label: (intl) =>
      intl.formatMessage({
        description: 'group status cancelled',
        defaultMessage: 'Cancelled',
      }),
  },
};

const Clusters = {
  Agency: {
    id: 'agency',
    idAllotment: 'agencies', // da uniformare a id!!
    isPublic: false,
    label: (intl) =>
      intl.formatMessage({
        description: 'agencies cluster label',
        defaultMessage: 'Agencies',
      }),
  },
  Pax: {
    id: 'pax',
    idAllotment: 'participation', // da uniformare a id!!
    isPublic: true,
    label: (intl) =>
      intl.formatMessage({
        description: 'pax cluster label',
        defaultMessage: 'Participant',
      }),
  },
  Delegations: {
    id: 'delegations',
    idAllotment: 'delegation', // da uniformare a id!!
    isPublic: true,
    label: (intl) =>
      intl.formatMessage({
        description: 'delegations cluster label',
        defaultMessage: 'Delegations',
      }),
    participationQueryKey: 'participationDelegationId',
    participationRequestQueryKey: 'participationRequestDelegationId',
  },
  Groups: {
    id: 'groups',
    isPublic: true,
    label: (intl) =>
      intl.formatMessage({
        description: 'groups cluster label',
        defaultMessage: 'Groups',
      }),
    participationQueryKey: 'participationAgencyGroupId',
    participationRequestQueryKey: 'participationRequestAgencyGroupId',
  },
  Sponsor: {
    id: 'sponsor',
    idAllotment: 'sponsor',
    isPublic: false,
    label: (intl) =>
      intl.formatMessage({
        description: 'sponsor cluster label',
        defaultMessage: 'Sponsor',
      }),
  },
  SponsorStaff: {
    id: 'sponsorstaff',
    isPublic: true,
    label: (intl) =>
      intl.formatMessage({
        description: 'sponsor staff label',
        defaultMessage: 'Sponsor staff',
      }),
  },
  SponsorList: {
    id: 'sponsorlist',
    isPublic: true,
    label: (intl) =>
      intl.formatMessage({
        description: 'Sponsored Participations label',
        defaultMessage: 'Sponsor Delegates',
      }),
    participationQueryKey: 'participationSponsorListId',
    participationRequestQueryKey: 'participationRequestSponsorListId',
  },
  Buyer: {
    id: 'buyer',
    isPublic: true,
    label: (intl) =>
      intl.formatMessage({
        description: 'buyer label',
        defaultMessage: 'Buyer',
      }),
    participationQueryKey: 'participationBuyerId',
    participationRequestQueryKey: 'participationRequestBuyerId',
  },
};

const Orientations = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
};

const Status = {
  DRAFT: 0,
  PUBLISHED: 1,
};

const reviewStatus = {
  REJECTED: 'rejected',
  PROCESSED: 'processed',
  PROCESSING: 'processing',
  TO_BE_PROCESSED: 'to be processed',
};

const advancedFiltersFieldTypes = {
  CUSTOM: 'custom',
  STANDARD: 'standard',
  NESTED: 'nested',
};

const SponsorOtherServicesTypes = {
  OTHER_STAND_SERVICES: 'otherStandServices',
  OTHER_SPONSORIZATIONS_SERVICES: 'otherSponsorizationsServices',
};

const NotificationsSchemas = [
  {
    category: 'ALLOTMENT',
    label: 'Allotment',
    items: [
      {
        type: 'ALLOTMENT_OVERBOOKING',
        title: 'Allotment Overbooking',
        string: 'Hotel {{hotelName}} is overbooked',
      },
    ],
  },
  {
    category: 'AGENCY_GROUP',
    label: 'Groups',
    items: [
      {
        type: 'AGENCY_GROUP_CLOSED',
        title: 'Group closed',
        string: 'Group {{name}} has been closed',
      },
    ],
  },
  {
    category: 'DELEGATION',
    label: 'Delegation',
    items: [
      {
        type: 'DELEGATION_CLOSED',
        title: 'Delegation closed',
        string: 'Delegation {{name}} has been closed',
      },
    ],
  },
  {
    category: 'SPONSOR',
    label: 'Sponsor',
    items: [
      {
        type: 'SPONSOR_LIST_CLOSED',
        title: 'Sponsor list closed',
        string: 'Sponsor list {{name}} has been closed',
      },
      {
        type: 'SPONSOR_STAFF_CLOSED',
        title: 'Sponsor staff closed',
        string: 'Sponsor staff {{name}} has been closed',
      },
    ],
  },
  {
    category: 'FACULTY',
    label: 'Faculty',
    items: [
      {
        type: 'SEND_PREFERENCES',
        title: 'Faculty user submitted preferences',
        string:
          'Faculty user {{participationUsername}} {{participationGivenName}} {{participationFamilyName}} submitted preferences',
      },
    ],
  },
  {
    category: 'PARTICIPANTS',
    label: 'Participants',
    items: [
      {
        type: 'NEW_FRONTOFFICE_ORDER',
        title: 'User has completed an order',
        string:
          'User {{participationUsername}} {{participationGivenName}} {{participationFamilyName}} has completed an order',
      },
      {
        type: 'NEW_CONFLICTS',
        title: 'Alert: Conflict',
        string:
          'There are {{conflictCount}} new conflicts. Click here to check!',
      },
    ],
  },
  {
    category: 'REVIEW',
    label: 'Review',
    items: [
      {
        type: 'REVIEW_REJECTED',
        title: 'Reviewer rejected review (conflict of interests)',
        string:
          'Reviewer {{participationUsername}} {{participationGivenName}} {{participationFamilyName}} has rejected review (conflict of interests) for abstract {{code}}',
      },
    ],
  },
  {
    category: 'ABSTRACT',
    label: 'Abstract',
    items: [
      {
        type: 'ABSTRACT_SUBMITTED',
        title: 'User has submitted an abstract',
        string:
          'User {{participationUsername}} {{participationGivenName}} {{participationFamilyName}} has submitted abstract {{code}}',
      },
      {
        type: 'ABSTRACT_FIRST_REMINDER',
        title: 'Abstract Reminder',
        string: 'First reminder for abstract submission sent',
      },
      {
        type: 'ABSTRACT_SECOND_REMINDER',
        title: 'Abstract Reminder',
        string: 'Second reminder for abstract submission sent',
      },
    ],
  },
];

const agendaSessionTypologies = [
  {
    id: 'roundTable',
    name: 'Round Table',
    description: 'Round Table',
    color: '#C650AA',
  },
  {
    id: 'mainSession',
    name: 'Main Session',
    description: 'Main Session',
    color: '#FE0C4E',
  },
  { id: 'plenary', name: 'Plenary', description: 'Plenary', color: '#14A6A7' },
  {
    id: 'meetTheExpert',
    name: 'Meet the expert',
    description: 'Meet the expert',
    color: '#145BCB',
  },
  {
    id: 'discussion',
    name: 'Discussion',
    description: 'Discussion',
    color: '#C7E5F2',
  },
  {
    id: 'symposium',
    name: 'Symposium',
    description: 'Symposium',
    color: '#FFEA87',
  },
  {
    id: 'keyNote',
    name: 'Key Note',
    description: 'Key Note',
    color: '#F28095',
  },
  {
    id: 'parallelSession',
    name: 'Parallel session',
    description: 'Parallel session',
    color: '#4AD6D6',
  },
  {
    id: 'sponsoredSession',
    name: 'Sponsored session',
    description: 'Sponsored session',
    color: '#FF9500',
  },
  {
    id: 'scientific',
    name: 'Scientific event',
    description: 'Scientific event',
    color: '#FF9500',
  },
];

const agendaSessionMode = [
  { id: '1', name: 'Webinar' },
  { id: '2', name: 'Only in presence' },
];

// attendeeAsPresenterSessionId: "", attendeeAsChairSessionId: "", attendeeAsFacilitatorSessionId: "", attendeeAsModeratorSessionId: "", attendeeAsPresidentSessionId: "", attendeeAsSpeakerSessionId: ""})

const agendaSessionAttendees = {
  President: {
    id: 'attendeePresidents',
    label: (intl) =>
      intl.formatMessage({
        description: 'President',
        defaultMessage: 'President',
      }),
    mutationString: 'attendeeAsPresidentSessionId',
    mutationPosterString: 'attendeeAsPresidentPosterSessionId',
  },
  Moderator: {
    id: 'attendeeModerators',
    label: (intl) =>
      intl.formatMessage({
        description: 'Moderator',
        defaultMessage: 'Moderator',
      }),
    mutationString: 'attendeeAsModeratorSessionId',
    mutationPosterString: 'attendeeAsModeratorPosterSessionId',
  },
  Chair: {
    id: 'attendeeChairs',
    label: (intl) =>
      intl.formatMessage({
        description: 'Chair',
        defaultMessage: 'Chair',
      }),
    mutationString: 'attendeeAsChairSessionId',
    mutationPosterString: 'attendeeAsChairPosterSessionId',
  },
  Facilitator: {
    id: 'attendeeFacilitators',
    label: (intl) =>
      intl.formatMessage({
        description: 'Facilitator',
        defaultMessage: 'Facilitator',
      }),
    mutationString: 'attendeeAsFacilitatorSessionId',
    mutationPosterString: 'attendeeAsFacilitatorPosterSessionId',
  },
  Speaker: {
    id: 'attendeeSpeakers',
    label: (intl) =>
      intl.formatMessage({
        description: 'Speaker',
        defaultMessage: 'Speaker',
      }),
    mutationString: 'attendeeAsSpeakerSessionId',
    mutationPosterString: 'attendeeAsSpeakerPosterSessionId',
  },
  Presenter: {
    id: 'attendeePresenters',
    label: (intl) =>
      intl.formatMessage({
        description: 'Presenter',
        defaultMessage: 'Presenter',
      }),
    mutationString: 'attendeeAsPresenterSessionId',
    mutationPosterString: 'attendeeAsPresenterPosterSessionId',
  },
};

const agendaSessionStatus = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

const agendaSessionManualManagementStatuses = {
  AUTOMATIC_MANAGED: 'automatic_management', //is setted to automatic management but session is not started
  MANUAL_MANAGED: 'manual_namaged', //is setted to manual management but session is not started
  STARTED: 'started', //is setted to manual management and session is started
  STOPPED: 'stopped', //is setted to manual management and session is ended
};

const posterSessionTypologies = {
  Poster: {
    id: 'poster',
    label: (intl) =>
      intl.formatMessage({
        description: 'Poster',
        defaultMessage: 'Poster',
      }),
  },
  EPoster: {
    id: 'eposter',
    label: (intl) =>
      intl.formatMessage({
        description: 'e-Poster',
        defaultMessage: 'e-Poster',
      }),
  },
};

const StandardFieldDataType = {
  PERSONAL: {
    id: 'personal',
    label: (intl) =>
      intl.formatMessage({
        description:
          'anagraphic configure add standard field personal data label',
        defaultMessage: 'Personal Data',
      }),
  },
  PROFESSIONAL: {
    id: 'professional',
    label: (intl) =>
      intl.formatMessage({
        description:
          'anagraphic configure add standard field professional data label',
        defaultMessage: 'Professional Data',
      }),
  },
  OCCUPATION: {
    id: 'occupation',
    label: (intl) =>
      intl.formatMessage({
        description:
          'anagraphic configure add standard field occupation data label',
        defaultMessage: 'Occupation',
      }),
  },
  STUDIES: {
    id: 'studies',
    label: (intl) =>
      intl.formatMessage({
        description:
          'anagraphic configure add standard field studies data label',
        defaultMessage: 'Studies',
      }),
  },
};

const StandardField = [
  // {
  //   key: 'title',
  //   controlType: 'select',
  //   label: 'Title',
  //   labelIta: 'Titolo',
  //   options: [
  //     'Arch',
  //     'As. prof',
  //     'Dipl',
  //     'Eng',
  //     'Miss',
  //     'Mr',
  //     'Mrs',
  //     'Ms',
  //     'Mx',
  //     'Odt',
  //     'Sir',
  //   ].map((t) => ({ label: t, value: t })),
  //   optionsIta: [
  //     'Arch',
  //     'As. prof',
  //     'Dipl',
  //     'Eng',
  //     'Miss',
  //     'Mr',
  //     'Mrs',
  //     'Ms',
  //     'Mx',
  //     'Odt',
  //     'Sir',
  //   ].map((t) => ({ label: t, value: t })),
  //   standardFieldType: StandardFieldDataType.PERSONAL.id,
  // },
  {
    key: 'contact_email',
    controlType: 'text',
    label: 'Contact email',
    labelIta: 'Email di contatto',
    options: undefined,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'address',
    controlType: 'text',
    label: 'Address',
    labelIta: 'Indirizzo',
    options: undefined,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'city',
    controlType: 'autocompletecity',
    label: 'City',
    labelIta: 'Città',
    options: null,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'zip_code',
    controlType: 'text',
    label: 'Zip code',
    labelIta: 'Cap',
    options: undefined,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'province',
    controlType: 'text',
    label: 'Province',
    labelIta: 'Provincia',
    options: null,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'region',
    controlType: 'text',
    label: 'Region',
    labelIta: 'Regione',
    options: null,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'country',
    controlType: 'autocompletecountries',
    label: 'Country',
    labelIta: 'Nazione',
    options: null,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'fiscal_code',
    controlType: 'text',
    label: 'Fiscal code',
    labelIta: 'Codice fiscale',
    options: undefined,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'vat_code',
    controlType: 'text',
    label: 'Vat code',
    labelIta: 'Partita iva',
    options: undefined,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'birth_date',
    controlType: 'date',
    label: 'Birth date',
    labelIta: 'Data di nascita',
    options: undefined,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'birth_place',
    controlType: 'text',
    label: 'Birth place',
    labelIta: 'Luogo di nascita',
    options: undefined,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'gender',
    controlType: 'select',
    label: 'Gender',
    labelIta: 'Genere',
    options: ['Male', 'Female', 'Other'].map((t) => ({ label: t, value: t })),
    optionsIta: ['Uomo', 'Donna', 'Altro'].map((t) => ({ label: t, value: t })),
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'dietary_needs',
    controlType: 'text',
    label: 'Dietary needs',
    labelIta: 'Esigenze alimentari',
    options: undefined,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'sponsor',
    controlType: 'text',
    label: 'Sponsor',
    labelIta: 'Sponsor',
    options: undefined,
    standardFieldType: StandardFieldDataType.PERSONAL.id,
  },
  {
    key: 'authority',
    controlType: 'text',
    label: 'Company/Institution',
    labelIta: 'Ente/istituto',
    options: null,
    standardFieldType: StandardFieldDataType.PROFESSIONAL.id,
  },
  {
    key: 'authority_city',
    controlType: 'text',
    label: 'Institution city',
    labelIta: "Città dell'ente/istituto",
    options: null,
    standardFieldType: StandardFieldDataType.PROFESSIONAL.id,
  },
  {
    key: 'authority_address',
    controlType: 'text',
    label: "Company/Institution's Address",
    labelIta: "Indirizzo dell'azienda/istituto",
    options: null,
    standardFieldType: StandardFieldDataType.PROFESSIONAL.id,
  },
  {
    key: 'authority_post_code',
    controlType: 'text',
    label: "Company/Institution's Zip Code",
    labelIta: "Cap. dell'azienda/istituto",
    options: null,
    standardFieldType: StandardFieldDataType.PROFESSIONAL.id,
  },
  {
    key: 'authority_province',
    controlType: 'text',
    label: "Company/Institution's Province",
    labelIta: "Provincia dell'azienda/istituto",
    options: null,
    standardFieldType: StandardFieldDataType.PROFESSIONAL.id,
  },
  {
    key: 'authority_region',
    controlType: 'text',
    label: "Company/Institution's Region",
    labelIta: "Regione dell'azienda/istituto",
    options: null,
    standardFieldType: StandardFieldDataType.PROFESSIONAL.id,
  },
  {
    key: 'authority_country',
    controlType: 'text',
    label: "Company/Institution's Country",
    labelIta: "Nazione dell'azienda/istituto",
    options: null,
    standardFieldType: StandardFieldDataType.PROFESSIONAL.id,
  },
  {
    key: 'authority_phone',
    controlType: 'text',
    label: "Company/Institution's Phone",
    labelIta: "telefono dell'azienda/istituto",
    options: null,
    standardFieldType: StandardFieldDataType.PROFESSIONAL.id,
  },
  {
    key: 'professional_qualification',
    controlType: 'text',
    label: 'Professional qualification',
    labelIta: 'Qualifica professionale',
    options: null,
    standardFieldType: StandardFieldDataType.OCCUPATION.id,
  },
  {
    key: 'occupation',
    controlType: 'text',
    label: 'Occupation',
    labelIta: 'Occupazione',
    options: null,
    standardFieldType: StandardFieldDataType.OCCUPATION.id,
  },
  {
    key: 'occupation_status',
    controlType: 'select',
    label: 'Occupation status',
    labelIta: 'Stato occupazionale',
    options: ['Freelancer', 'Employee', 'Unoccupied'].map((t) => ({
      label: t,
      value: t,
    })),
    optionsIta: [
      'Libero professionista',
      'Lavoratore dipendente',
      'Disoccupato',
    ].map((t) => ({ label: t, value: t })),
    standardFieldType: StandardFieldDataType.OCCUPATION.id,
  },
  {
    key: 'occupation_type',
    controlType: 'text',
    label: 'Occupation type',
    labelIta: 'Tipo di occupazione',
    options: null,
    standardFieldType: StandardFieldDataType.OCCUPATION.id,
  },
  {
    key: 'job',
    controlType: 'select',
    label: 'Job',
    labelIta: 'Professione',
    options: [
      'Medical surgeon',
      'Dentist',
      'Pharmacist',
      'Veterinary surgeon',
      'Psychologist',
      'Biologist',
      'Chemist',
      'Physicist',
      'Health care assistant',
      'Dietician',
      'Professional educator',
      'Physiotherapist',
      'Dental hygienist',
      'Nurses',
      'Paediatric nurse',
      'Speech therapist',
      'Orthoptist/ophthalmology assistant',
      'Midwife',
      'Podiatrist',
      'Audiometric technician',
      'Hearing aid technician',
      'Cardiocirculatory physiopathology and cardiovascular perfusion technician',
      'Environmental and workplace prevention technician',
      'Psychiatric rehabilitation technician',
      'Neurophysiopathology technician',
      'Orthopaedic technician',
      'Medical radiology health technician',
      'Biomedical laboratory health technician',
      'Developmental neuro and psychomotricity therapist',
      'Occupational therapist',
      'Developmental neuro and psychomotricity therapist',
      'Occupational therapist',
      'Prevention technician in the environment and workplace',
      'Food science and dietetics',
      'Hospital medical management',
      'Organisation of basic health services',
      'Audiology and phoniatrics',
      'Psychotherapy',
      'Without specialisation *',
      'Palliative care',
      'Epidemiology',
      'Community medicine',
      'Underwater and hyperbaric medicine',
    ].map((j) => ({ label: j, value: j })),
    optionsIta: [
      'Medico chirurgo',
      'Odontoiatra',
      'Farmacista',
      'Veterinario',
      'Psicologo',
      'Biologo',
      'Chimico',
      'Fisico',
      'Assistente sanitario',
      'Dietista',
      'Educatore professionale',
      'Fisioterapista',
      'Igienista dentale',
      'Infermiere',
      'Infermiere pediatrico',
      'Logopedista',
      'Ortottista/assistente di oftalmologia',
      'Ostetrica/o',
      'Podologo',
      'Tecnico audiometrista',
      'Tecnico audioprotesista',
      'Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare',
      "Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro",
      'Tecnico della riabilitazione psichiatrica',
      'Tecnico di neurofisiopatologia',
      'Tecnico ortopedico',
      'Tecnico sanitario laboratorio biomedico',
      "Terapista della neuro e psicomotricità dell'età evolutiva",
      'Terapista occupazionale',
      "Terapista della neuro e psicomotricità dell'età evolutiva",
      'Terapista occupazionale',
      "Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro",
      "Scienza dell'alimentazione e dietetica",
      'Direzione medica di presidio ospedaliero',
      'Organizzazione dei servizi sanitari di base',
      'Audiologia e foniatria',
      'Psicoterapia',
      'Privo di specializzazione *',
      'Cure palliative',
      'Epidemiologia',
      'Medicina di comunità',
      'Medicina subacquea e iperbarica',
    ].map((j) => ({ label: j, value: j })),
    standardFieldType: StandardFieldDataType.OCCUPATION.id,
  },
  {
    key: 'job_description',
    controlType: 'text',
    label: 'Job description',
    labelIta: 'Ruolo',
    options: null,
    standardFieldType: StandardFieldDataType.OCCUPATION.id,
  },
  {
    key: 'degree',
    controlType: 'text',
    label: 'Degree',
    labelIta: 'Laurea',
    options: null,
    standardFieldType: StandardFieldDataType.STUDIES.id,
  },
  {
    key: 'specialization',
    controlType: 'text',
    label: 'Specialization',
    labelIta: 'Specializzazione',
    options: null,
    standardFieldType: StandardFieldDataType.STUDIES.id,
  },
  {
    key: 'certifications',
    controlType: 'text',
    label: 'Certifications',
    labelIta: 'Certificazioni',
    options: null,
    standardFieldType: StandardFieldDataType.STUDIES.id,
  },

  {
    key: 'area',
    controlType: 'text',
    label: 'Area',
    labelIta: 'Area',
    options: null,
    standardFieldType: StandardFieldDataType.STUDIES.id,
  },
  {
    key: 'specialization_status',
    controlType: 'select',
    label: 'Specialization status',
    labelIta: 'Stato della specializzazione',
    options: ['Specialized', 'Specializing'].map((j) => ({
      label: j,
      value: j,
    })),
    optionsIta: ['Specializzato', 'Specializzando'].map((j) => ({
      label: j,
      value: j,
    })),
    standardFieldType: StandardFieldDataType.STUDIES.id,
  },

  {
    key: 'discipline',
    controlType: 'select',
    label: 'Discipline',
    labelIta: 'Disciplina',
    options: [
      'Allergology and clinical immunology',
      'Angiology',
      'Cardiology',
      'Dermatology and venereology',
      'Haematology',
      'Endocrinology',
      'Gastroenterology',
      'Medical genetics',
      'Geriatrics',
      'Metabolic diseases and diabetology',
      'Respiratory diseases',
      'Infectious diseases',
      'Emergency and Hospital Medicine and Surgery',
      'Physical Medicine and rehabilitation',
      'Internal Medicine',
      'Thermal Medicine',
      'Aerospace Medicine',
      'Sports Medicine',
      'Nephrology',
      'Neonatology',
      'Neurology',
      'Child Neuropsychiatry',
      'Oncology',
      'Paediatrics',
      'Psychiatry',
      'Radiotherapy',
      'Rheumatology',
      'Cardiac Surgery',
      'General Surgery',
    ].map((d) => ({ label: d, value: d })),
    optionsIta: [
      'Allergologia ed immunologia clinica',
      'Angiologia',
      'Cardiologia',
      'Dermatologia e venereologia',
      'Ematologia',
      'Endocrinologia',
      'Gastroenterologia',
      'Genetica medica',
      'Geriatria',
      'Malattie metaboliche e diabetologia',
      "Malattie dell'apparato respiratorio",
      'Malattie infettive',
      'Medicina e chirurgia di accettazione e di urgenza',
      'Medicina fisica e riabilitazione',
      'Medicina interna',
      'Medicina termale',
      'Medicina aeronautica e spaziale',
      'Medicina dello sport',
      'Nefrologia',
      'Neonatologia',
      'Neurologia',
      'Neuropsichiatria infantile',
      'Oncologia',
      'Pediatria',
      'Psichiatria',
      'Radioterapia',
      'Reumatologia',
      'Cardiochirurgia',
      'Chirurgia generale',
    ].map((d) => ({ label: d, value: d })),
    standardFieldType: StandardFieldDataType.STUDIES.id,
  },
  {
    key: 'discipline_description',
    controlType: 'text',
    label: 'Discipline description',
    labelIta: 'Descrizione della disciplina',
    options: null,
    standardFieldType: StandardFieldDataType.STUDIES.id,
  },
];

const StandardFieldAbstract = [
  {
    key: 'abstract_title',
    controlType: 'text',
    label: 'Title',
    labelIta: 'Titolo',
    placeholder: 'Title',
    options: undefined,
  },
  /* {
    key: 'abstract_name_dotted',
    controlType: 'text',
    label: 'Name (dotted)',
    labelIta: 'Nome (puntato)',
    placeholder: 'Name (dotted)',
    options: null,
  }, */
  {
    key: 'abstract_name_extended',
    controlType: 'text',
    label: 'Name',
    labelIta: 'Nome',
    placeholder: 'Name',
    options: undefined,
  },
  {
    key: 'abstract_surname',
    controlType: 'text',
    label: 'Surname',
    labelIta: 'Cognome',
    placeholder: 'Surname',
    options: undefined,
  },
  {
    key: 'abstract_email',
    controlType: 'text',
    label: 'Email',
    labelIta: 'Email',
    placeholder: 'Email',
    options: undefined,
  },
  {
    key: 'abstract_city',
    controlType: 'text',
    label: 'City',
    labelIta: 'Città',
    placeholder: 'City',
    options: undefined,
  },
  {
    key: 'abstract_country',
    controlType: 'text',
    label: 'Country',
    labelIta: 'Nazione',
    placeholder: 'Country',
    options: undefined,
  },
];

const AbstractMotivations = {
  Rejected: {
    key: 'rejected',
    label: (intl) =>
      intl.formatMessage({
        description: 'rejected abstract motivations label',
        defaultMessage: 'Rejected',
      }),
  },
  DoubleSubmission: {
    key: 'duble_submission',
    label: (intl) =>
      intl.formatMessage({
        description: 'Duble submission abstract motivations label',
        defaultMessage: 'Duble submission',
      }),
  },
  Test: {
    key: 'test',
    label: (intl) =>
      intl.formatMessage({
        description: 'test abstract motivations label',
        defaultMessage: 'Test',
      }),
  },
  Draft: {
    key: 'draft',
    label: (intl) =>
      intl.formatMessage({
        description: 'draft abstract motivations label',
        defaultMessage: 'Draft',
      }),
  },
  Withdrawn: {
    key: 'withdrawn',
    label: (intl) =>
      intl.formatMessage({
        description: 'withdrawn abstract motivations label',
        defaultMessage: 'Withdrawn',
      }),
  },
  Canceled: {
    key: 'canceled',
    label: (intl) =>
      intl.formatMessage({
        description: 'canceled abstract motivations label',
        defaultMessage: 'Canceled',
      }),
  },
  AddManually: {
    key: 'add_manually',
    label: (intl) =>
      intl.formatMessage({
        description: 'add manually abstract motivations label',
        defaultMessage: 'Add manually',
      }),
  },
};

const TitleOptions = [
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title arch. label',
        defaultMessage: 'Arch.',
      }),
    value: 'arch',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title prof label',
        defaultMessage: 'Prof.',
      }),
    value: 'asprof',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title dipl. label',
        defaultMessage: 'Dipl.',
      }),
    value: 'dipl',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title dr. label',
        defaultMessage: 'Dr.',
      }),
    value: 'dr',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title dr.a label',
        defaultMessage: 'Dr.a',
      }),
    value: 'dra',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title eng. label',
        defaultMessage: 'Eng.',
      }),
    value: 'eng',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title miss. label',
        defaultMessage: 'Miss.',
      }),
    value: 'miss',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title mr. label',
        defaultMessage: 'Mr.',
      }),
    value: 'mr',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title mrs. label',
        defaultMessage: 'Mrs.',
      }),
    value: 'mrs',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title ms. label',
        defaultMessage: 'Ms.',
      }),
    value: 'ms',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title mx. label',
        defaultMessage: 'Mx.',
      }),
    value: 'mx',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title odt. label',
        defaultMessage: 'Odt.',
      }),
    value: 'odt',
  },
  {
    label: (intl) =>
      intl.formatMessage({
        description: 'title sir. label',
        defaultMessage: 'Sir.',
      }),
    value: 'sir',
  },
];

const VisaFields = [
  {
    key: 'visa_title',
    controlType: 'select',
    label: 'Title',
    labelIta: 'titolo',
    placeholder: 'Title',
    options: [
      'Dott.',
      'Dott.ssa',
      'Prof.',
      'Prof.ssa',
      'Sig.',
      'Sig.ra',
    ].map((t) => ({ label: t, value: t })),
    optionsIta: [
      'Dott.',
      'Dott.ssa',
      'Prof.',
      'Prof.ssa',
      'Sig.',
      'Sig.ra',
    ].map((t) => ({ label: t, value: t })),
  },
  {
    key: 'visa_passport_number',
    controlType: 'text',
    label: 'Passport Number',
    labelIta: 'Passaporto Numero',
    options: undefined,
  },
  {
    key: 'visa_issued_by',
    controlType: 'text',
    label: 'Issued By',
    labelIta: 'Rilasciato da',
    options: undefined,
  },
  {
    key: 'visa_date_of_issue',
    controlType: 'date',
    label: 'Date of Issue',
    labelIta: 'Data di rilascio',
    options: undefined,
  },
  {
    key: 'visa_place_of_issue',
    controlType: 'text',
    label: 'Place of Issue',
    labelIta: 'Luogo del rilascio',
    options: undefined,
  },
  {
    key: 'visa_expiry_date',
    controlType: 'date',
    label: 'Expiry Date',
    labelIta: 'Data di scadenza',
    options: undefined,
  },
  {
    key: 'visa_participation_country',
    controlType: 'text',
    label: 'Country',
    labelIta: 'Paese',
    options: undefined,
  },
  {
    key: 'visa_embassy',
    controlType: 'text',
    label: 'Embassy',
    labelIta: 'Ambasciata',
    options: undefined,
  },
  {
    key: 'visa_date',
    controlType: 'date',
    label: 'Date',
    labelIta: 'Data',
    options: undefined,
  },
];

const EventGroups = {
  GENERAL_INFO: {
    key: 'GENERAL_INFO',
    position: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'general info label',
        defaultMessage: 'General Info',
      }),
  },
  AGENCIES_AND_SPONSOR: {
    key: 'AGENCIES_AND_SPONSOR',
    position: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'agencies and sponsor label',
        defaultMessage: 'Agencies And Sponsor',
      }),
  },
  COMMUNITY: {
    key: 'COMMUNITY',
    position: 2,
    label: (intl) =>
      intl.formatMessage({
        description: 'community label',
        defaultMessage: 'Community',
      }),
  },
  SERVICES: {
    key: 'SERVICES',
    position: 3,
    label: (intl) =>
      intl.formatMessage({
        description: 'services label',
        defaultMessage: 'Services',
      }),
  },
  INFO_MANAGEMENT: {
    key: 'INFO_MANAGEMENT',
    position: 4,
    label: (intl) =>
      intl.formatMessage({
        description: 'info management label',
        defaultMessage: 'Info Management',
      }),
  },
};

const UserTypes = {
  ADMIN: {
    id: 'admin',
    cognitoGroup: 'admin',
    label: (intl) =>
      intl.formatMessage({
        description: 'admin user label',
        defaultMessage: 'Admin',
      }),
  },
  CONGRESS: {
    id: 'congress',
    cognitoGroup: 'admin',
    label: (intl) =>
      intl.formatMessage({
        description: 'Congress user label',
        defaultMessage: 'Congress',
      }),
  },
  SCIENTIFIC_COMMITTEE: {
    id: 'scientificCommittee',
    cognitoGroup: 'scientificCommittee',
    label: (intl) =>
      intl.formatMessage({
        description: 'scientific committee user label',
        defaultMessage: 'Scientific Committee',
      }),
  },
  TRAVEL: {
    id: 'travel',
    cognitoGroup: 'admin',
    label: (intl) =>
      intl.formatMessage({
        description: 'travel user label',
        defaultMessage: 'Travel',
      }),
  },
  TICKET: {
    id: 'ticket',
    cognitoGroup: 'ticket',
    label: (intl) =>
      intl.formatMessage({
        description: 'ticket user label',
        defaultMessage: 'Ticket',
      }),
  },
  HOSTESS: {
    id: 'hostess',
    cognitoGroup: 'admin',
    label: (intl) =>
      intl.formatMessage({
        description: 'hostess user label',
        defaultMessage: 'Hostess',
      }),
  },
};

const EventTypes = {
  HYBRID: {
    id: 'hybrid',
    label: (intl) =>
      intl.formatMessage({
        description: 'hybrid event label',
        defaultMessage: 'Hybrid',
      }),
  },
  PHYSICAL: {
    id: 'physical',
    label: (intl) =>
      intl.formatMessage({
        description: 'physical event label',
        defaultMessage: 'Physical',
      }),
  },
  VIRTUAL: {
    id: 'virtual',
    label: (intl) =>
      intl.formatMessage({
        description: 'virtual event label',
        defaultMessage: 'Virtual',
      }),
  },
};

const Services = {
  EVENT_DATA: {
    key: 'EVENT_DATA',
    label: (intl) =>
      intl.formatMessage({
        description: 'event data',
        defaultMessage: 'Event Data',
      }),
    status: 'done',
    path: () => 'edit',
    group: 'GENERAL_INFO',
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  FEE_CONFIGURATION: {
    key: 'FEE_CONFIGURATION',
    label: (intl) =>
      intl.formatMessage({
        description: 'fee configuration',
        defaultMessage: 'Fee configuration',
      }),
    subtitle: (intl) =>
      intl.formatMessage({
        description: '(days, brackets)',
        defaultMessage: '(days, brackets)',
      }),
    status: 'pending',
    path: () => 'participation/quote',
    group: 'GENERAL_INFO',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  PROFILES: {
    key: 'PROFILES',
    label: (intl) =>
      intl.formatMessage({
        description: 'profiles',
        defaultMessage: 'Profiles',
      }),
    status: 'pending',
    path: () => 'participation/profiles',
    group: 'GENERAL_INFO',
    userTypes: ['congress', 'admin'],
    hideable: false,
  },
  TYPOLOGIES: {
    key: 'TYPOLOGIES',
    label: (intl) =>
      intl.formatMessage({
        description: 'typologies',
        defaultMessage: 'Typologies',
      }),
    status: 'pending',
    path: () => 'participation/typologies',
    group: 'GENERAL_INFO',
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  GATEWAY: {
    key: 'GATEWAY',
    label: (intl) =>
      intl.formatMessage({
        description: 'gateway',
        defaultMessage: 'Gateway',
      }),
    status: 'pending',
    path: () => 'gateway',
    group: 'GENERAL_INFO',
    userTypes: ['congress', 'admin'],
    hideable: false,
  },
  REGISTRATION_FORM: {
    key: 'REGISTRATION_FORM',
    label: (intl) =>
      intl.formatMessage({
        description: 'registration form',
        defaultMessage: 'Registration form',
      }),
    status: 'pending',
    path: () => 'participation/registration-form',
    group: 'GENERAL_INFO',
    userTypes: ['congress', 'admin'],
    hideable: false,
  },
  AGENCIES: {
    key: 'AGENCIES',
    label: (intl) =>
      intl.formatMessage({
        description: 'agencies',
        defaultMessage: 'Agencies',
      }),
    status: 'pending',
    path: () => 'agency',
    group: 'AGENCIES_AND_SPONSOR',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  SPONSOR: {
    key: 'SPONSOR',
    label: (intl) =>
      intl.formatMessage({
        description: 'sponsor',
        defaultMessage: 'Sponsor',
      }),
    status: 'pending',
    path: () => 'sponsor',
    group: 'AGENCIES_AND_SPONSOR',
    userTypes: ['congress', 'admin'],
    hideable: true,
    connectedServices: ['sponsor_staff', 'sponsor_list'],
  },
  PARTICIPATION: {
    key: 'PARTICIPATION',
    label: (intl) =>
      intl.formatMessage({
        description: 'Participants',
        defaultMessage: 'Participants',
      }),
    status: 'pending',
    path: () => 'participation/clusters/pax',
    group: 'COMMUNITY',
    userTypes: ['congress', 'admin', 'hostess'],
    hideable: false,
  },
  DELEGATIONS: {
    key: 'DELEGATIONS',
    label: (intl) =>
      intl.formatMessage({
        description: 'delegations',
        defaultMessage: 'Delegations',
      }),
    status: 'pending',
    path: () => 'participation/delegations',
    group: 'COMMUNITY',
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  GROUPS: {
    key: 'GROUPS',
    label: (intl) =>
      intl.formatMessage({
        description: 'groups',
        defaultMessage: 'Groups',
      }),
    status: 'pending',
    path: () => 'agency/groups',
    group: 'COMMUNITY',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  SPONSOR_LIST: {
    key: 'SPONSOR_LIST',
    label: (intl) =>
      intl.formatMessage({
        description: 'Sponsored Participations',
        defaultMessage: 'Sponsored Participations',
      }),
    status: 'pending',
    path: () => 'participation/sponsor-list',
    group: 'COMMUNITY',
    userTypes: ['congress', 'admin'],
    hideable: true,
    connectedServices: ['sponsor', 'sponsor_staff'],
  },
  SPONSOR_STAFF: {
    key: 'SPONSOR_STAFF',
    label: (intl) =>
      intl.formatMessage({
        description: 'sponsor staff',
        defaultMessage: 'Sponsor Staff',
      }),
    status: 'pending',
    path: () => 'participation/sponsor-staff',
    group: 'COMMUNITY',
    userTypes: ['congress', 'admin'],
    hideable: true,
    connectedServices: ['sponsor'],
  },
  FACULTY: {
    key: 'FACULTY',
    label: (intl) =>
      intl.formatMessage({
        description: 'faculty',
        defaultMessage: 'Faculty',
      }),
    status: 'pending',
    path: () => 'participation/faculty',
    group: 'COMMUNITY',
    userTypes: ['congress', 'admin'],
    hideable: false,
  },
  ABSTRACT: {
    key: 'ABSTRACT',
    label: (intl) =>
      intl.formatMessage({
        description: 'abstract',
        defaultMessage: 'Abstract',
      }),
    status: 'pending',
    path: (event, userType) =>
      event && event.abstractService
        ? `abstract-service/${event.abstractService.id}/abstracts${
            userType === 'scientificCommittee' ? '/scientific-committee' : ''
          }`
        : 'abstract-service/abstracts',
    group: 'SERVICES',
    userTypes: ['congress', 'scientificCommittee', 'admin'],
    hideable: true,
  },
  PROGRAM: {
    key: 'PROGRAM',
    label: (intl) =>
      intl.formatMessage({
        description: 'program',
        defaultMessage: 'Program',
      }),
    status: 'pending',
    path: () => 'agenda',
    group: 'SERVICES',
    userTypes: ['congress', 'scientificCommittee', 'admin'],
    hideable: true,
  },
  // ELEARNING: {
  //   key: 'ELEARNING',
  //   label: (intl) =>
  //     intl.formatMessage({
  //       description: 'elearning',
  //       defaultMessage: 'Elearning',
  //     }),
  //   status: 'dpendingone',
  //   path: () => '',
  //   group: 'SERVICES',
  //   userTypes: ['congress', 'admin'],
  // },
  WEBCAST: {
    key: 'WEBCAST',
    label: (intl) =>
      intl.formatMessage({
        description: 'webcast',
        defaultMessage: 'Webcast',
      }),
    status: 'pending',
    path: () => 'webcasts',
    group: 'SERVICES',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  // ECM: {
  //   key: 'ECM',
  //   label: (intl) =>
  //     intl.formatMessage({
  //       description: 'ECM',
  //       defaultMessage: 'ECM',
  //     }),
  //   status: 'pending',
  //   group: 'SERVICES',
  //   userTypes: ['congress', 'admin'],
  // },
  PIC: {
    key: 'PIC',
    label: (intl) =>
      intl.formatMessage({
        description: 'pic description',
        defaultMessage: 'Platform Interface Configuration',
      }),
    status: 'pending',
    path: () => 'event-configuration',
    group: 'GENERAL_INFO',
    userTypes: ['congress', 'admin'],
    hideable: false,
  },
  ALLOTMENT: {
    key: 'ALLOTMENT',
    label: (intl) =>
      intl.formatMessage({
        description: 'allotment',
        defaultMessage: 'Allotment',
      }),
    status: 'pending',
    path: () => 'allotment',
    group: 'SERVICES',
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  TRAVEL_TRANSFER: {
    key: 'TRAVEL_TRANSFER',
    label: (intl) =>
      intl.formatMessage({
        description: 'travel & transfer',
        defaultMessage: 'Travel & Transfer',
      }),
    status: 'pending',
    path: () => 'travel-transfer',
    group: 'SERVICES',
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  SOCIAL_EVENT: {
    key: 'SOCIAL_EVENT',
    label: (intl) =>
      intl.formatMessage({
        description: 'Additional services',
        defaultMessage: 'Additional services',
      }),
    status: 'pending',
    path: () => 'additional-services',
    group: 'SERVICES',
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  SCIENTIFIC_EVENT: {
    key: 'SCIENTIFIC_EVENT',
    label: (intl) =>
      intl.formatMessage({
        description: 'scientific event',
        defaultMessage: 'Scientific event',
      }),
    status: 'pending',
    path: () => 'additional-services',
    group: '', //messo qui a vuoto per nasconderlo..non sono sicuro se è usato da qualcuno
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  GRANT: {
    key: 'GRANT',
    label: (intl) =>
      intl.formatMessage({
        description: 'grant',
        defaultMessage: 'Grant',
      }),
    status: 'pending',
    path: () => 'grant',
    group: 'SERVICES',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  VISA: {
    key: 'VISA',
    label: (intl) =>
      intl.formatMessage({
        description: 'visa',
        defaultMessage: 'Visa',
      }),
    status: 'pending',
    path: () => 'participation/visa',
    group: 'SERVICES',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  BADGES_CERTIFICATES: {
    key: 'BADGES_CERTIFICATES',
    label: (intl) =>
      intl.formatMessage({
        description: 'badges_certificates',
        defaultMessage: 'Badges & Certificates',
      }),
    status: 'pending',
    path: () => 'participation/badges-certificates',
    group: 'SERVICES',
    userTypes: ['congress', 'admin'],
    hideable: false,
  },
  MAIL: {
    key: 'MAIL',
    label: (intl) =>
      intl.formatMessage({
        description: 'event mail service label',
        defaultMessage: 'Mail',
      }),
    status: 'pending',
    path: () => 'emailTemplates',
    group: 'SERVICES',
    userTypes: ['congress', 'admin', 'travel'],
    hideable: false,
  },
  LETTER: {
    key: 'LETTER',
    label: (intl) =>
      intl.formatMessage({
        description: 'event letter general info service label',
        defaultMessage: 'Letter General Info',
      }),
    status: 'pending',
    path: () => 'generalInfo',
    group: 'SERVICES',
    userTypes: ['congress', 'admin', 'travel'],
    hideable: false,
  },
  WELCOME: {
    key: 'WELCOME',
    label: (intl) =>
      intl.formatMessage({
        description: 'welcome',
        defaultMessage: 'Welcome',
      }),
    status: 'pending',
    path: () => 'participation/welcome',
    group: 'SERVICES',
    userTypes: ['congress', 'admin', 'travel'],
    hideable: false,
  },
  STATISTICS: {
    key: 'STATISTICS',
    label: (intl) =>
      intl.formatMessage({
        description: 'statistics',
        defaultMessage: 'Statistics',
      }),
    status: 'pending',
    path: () => '',
    group: 'INFO_MANAGEMENT',
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  ORDER_MANAGEMENT: {
    key: 'ORDER_MANAGEMENT',
    label: (intl) =>
      intl.formatMessage({
        description: 'Order Management',
        defaultMessage: 'Order Management',
      }),
    status: 'pending',
    path: () => 'allotment/order',
    group: 'INFO_MANAGEMENT',
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  COMUNICATION: {
    key: 'COMUNICATION',
    label: (intl) =>
      intl.formatMessage({
        description: 'communication',
        defaultMessage: 'Communication',
      }),
    status: 'pending',
    path: () => '',
    group: 'INFO_MANAGEMENT',
    userTypes: ['travel', 'congress', 'admin'],
    hideable: false,
  },
  SURVEY: {
    key: 'SURVEY',
    label: (intl) =>
      intl.formatMessage({
        description: 'survey',
        defaultMessage: 'Survey',
      }),
    status: 'pending',
    path: () => 'surveys',
    group: 'INFO_MANAGEMENT',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  REPOSITORY: {
    key: 'REPOSITORY',
    label: (intl) =>
      intl.formatMessage({
        description: 'content repository',
        defaultMessage: 'Content Repository',
      }),
    status: 'pending',
    path: () => 'repository',
    group: 'INFO_MANAGEMENT',
    userTypes: ['congress', 'admin'],
    hideable: false,
  },
  BILLING: {
    key: 'BILLING',
    label: (intl) =>
      intl.formatMessage({
        description: 'Billing',
        defaultMessage: 'Billing',
      }),
    status: 'pending',
    path: () => 'billing',
    group: 'INFO_MANAGEMENT',
    userTypes: ['congress', 'admin'],
    hideable: false,
  },
  REPORTS: {
    key: 'REPORTS',
    label: (intl) =>
      intl.formatMessage({
        description: 'Reports',
        defaultMessage: 'Reports',
      }),
    status: 'pending',
    path: () => 'reports',
    group: 'INFO_MANAGEMENT',
    userTypes: ['congress', 'admin', 'travel'],
    hideable: false,
  },
  virtualBag: {
    key: 'virtualBag',
    label: (intl) =>
      intl.formatMessage({
        description: 'virtualBag description',
        defaultMessage: 'Virtual Bag',
      }),
    status: 'pending',
    path: () => 'virtual-bag',
    group: 'INFO_MANAGEMENT',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  socialWall: {
    key: 'socialWall',
    label: (intl) =>
      intl.formatMessage({
        description:
          'this is a place where the user can manage posts from social platforms such as twitter (for the moment) and so on',
        defaultMessage: 'Social Wall',
      }),
    status: 'pending',
    path: () => 'social-wall',
    group: 'INFO_MANAGEMENT',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  REGISTRATION: {
    key: 'REGISTRATION',
    label: (intl) =>
      intl.formatMessage({
        description: 'registration',
        defaultMessage: 'Registration',
      }),
    status: 'pending',
    path: () => 'registration',
    group: 'SERVICES',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
  TITLE_CONFIGURATION: {
    key: 'TITLE_CONFIGURATION',
    label: (intl) =>
      intl.formatMessage({
        description: 'title configuration',
        defaultMessage: 'Title Configuration',
      }),
    status: 'pending',
    path: () => 'title-configuration',
    group: 'GENERAL_INFO',
    userTypes: ['congress', 'admin', 'travel'],
    hideable: false,
  },
  PRIVACY: {
    key: 'PRIVACY',
    label: (intl) =>
      intl.formatMessage({
        description: 'privacy configuration',
        defaultMessage: 'Privacy',
      }),
    status: 'pending',
    path: () => 'privacy',
    group: 'GENERAL_INFO',
    userTypes: ['congress', 'admin', 'travel'],
    hideable: false,
  },
  CUSTOMIZED_ORDER: {
    key: 'CUSTOMIZED_ORDER',
    label: (intl) =>
      intl.formatMessage({
        description: 'customized orders description',
        defaultMessage: 'Customized orders',
      }),
    status: 'pending',
    path: () => 'customized-orders',
    group: 'INFO_MANAGEMENT',
    userTypes: ['congress', 'admin'],
    hideable: true,
  },
};

const SponsorServicesType = {
  OTHER_SPONSORIZATIONS: {
    id: 'otherSponsorizations',
    label: (intl) =>
      intl.formatMessage({
        description: 'other sponsorizations label',
        defaultMessage: 'Other sponsorizations',
      }),
  },
  OTHER_STAND_SERVICE: {
    id: 'otherStandService',
    label: (intl) =>
      intl.formatMessage({
        description: 'other stand service label',
        defaultMessage: 'Other stand service',
      }),
  },
};

const ParticipationWallItems = {
  ANAGRAPHICSETTINGS: {
    key: 'ANAGRAPHICSETTINGS',
    id: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'anagraphic settings',
        defaultMessage: 'Anagraphic settings',
      }),
    status: 'done',
    link: 'services',
    userTypes: ['travel', 'congress', 'admin'],
    clusters: [Clusters.Pax.id], // , Clusters.Delegations.id
  },
  /* PARTICIPATIONFEE: {
    key: 'PARTICIPATIONFEE',
    id: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'participation fee',
        defaultMessage: 'Participation fee',
      }),
    status: 'done',
    link: 'welcome-page',
    userTypes: ['congress', 'admin'],
    clusters: [Clusters.Pax.id],
  }, */
  CONFLICTS: {
    key: 'CONFLICTS',
    id: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'conflicts',
        defaultMessage: 'Conflict management',
      }),
    status: 'done',
    link: 'conflicts',
    userTypes: ['congress', 'admin'],
    clusters: [Clusters.Pax.id],
  },
  MODIFICATIONREQUEST: {
    key: 'MODIFICATIONREQUEST',
    id: 3,
    label: (intl) =>
      intl.formatMessage({
        description: 'Modification request label',
        defaultMessage: 'Modification Request',
      }),
    status: 'done',
    link: 'premium-ranges',
    userTypes: ['congress', 'admin', 'travel'],
    clusters: [Clusters.Pax.id, Clusters.Delegations.id],
  },
  SPECIFICATIONS: {
    key: 'SPECIFICATIONS',
    id: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'deadline, penalties',
        defaultMessage: 'Deadline, penalties',
      }),
    status: 'done',
    link: 'deadline',
    userTypes: ['congress', 'admin'],
    clusters: [Clusters.Delegations.id],
  },
  PURCHASES: {
    key: 'PURCHASES',
    id: 4,
    label: (intl) =>
      intl.formatMessage({
        description: 'purchases',
        defaultMessage: 'Purchases',
      }),
    status: 'done',
    link: 'purchases',
    userTypes: ['congress', 'admin'],
    clusters: [Clusters.Pax.id],
  },
};

const GatewayServices = () => ({
  ALLOTMENT: {
    key: 'ALLOTMENT',
    id: 0,
    label: 'Allotment',
  },
  REGISTRATION: {
    key: 'REGISTRATION',
    id: 1,
    label: 'Registration',
  },
  EVENT: {
    key: 'EVENT',
    id: 2,
    label: 'Event',
  },
  SPONSOR: {
    key: 'SPONSOR',
    id: 3,
    label: 'Sponsor',
  },
  GROUP: {
    key: 'GROUP',
    id: 4,
    label: 'Group',
  },
  SOCIAL_EVENT: {
    key: 'SOCIAL_EVENT',
    id: 5,
    label: 'Social Event',
  },
  SCIENTIFIC_EVENT: {
    key: 'SCIENTIFIC_EVENT',
    id: 6,
    label: 'Scientific Event',
  },
});

const SponsorServices = () => ({
  exhibition: {
    label: (intl) =>
      intl.formatMessage({
        description: 'sponsorship',
        defaultMessage: 'Sponsorship',
      }),
    keyName: 'sponsorship',
    items: [
      {
        id: 1,
        key: 'physical-stand',
        title: (intl) =>
          intl.formatMessage({
            description: 'Physical Stand',
            defaultMessage: 'Physical Stand',
          }),
        link: 'physical-stand',
        isVisible: true,
        eventTypes: [EventTypes.HYBRID.id, EventTypes.PHYSICAL.id],
        order: 0,
      },
      {
        id: 5,
        key: 'virtual-stand',
        title: (intl) =>
          intl.formatMessage({
            description: 'Virtual Stand',
            defaultMessage: 'Virtual Stand',
          }),
        link: 'virtual-stand',
        isVisible: true,
        eventTypes: [EventTypes.HYBRID.id, EventTypes.VIRTUAL.id],
        order: 1,
      },
      {
        id: 12,
        key: 'other-sponsorizations',
        title: (intl) =>
          intl.formatMessage({
            description: 'Other Sponsorizations Services',
            defaultMessage: 'Other Sponsorizations',
          }),
        link: 'other-sponsorizations',
        hideFrontoffice: false,
        isVisible: true,
        eventTypes: [EventTypes.HYBRID.id, EventTypes.PHYSICAL.id],
        order: 2,
      },
      {
        id: 6,
        key: 'public-page',
        title: (intl) =>
          intl.formatMessage({
            description: 'Public Page',
            defaultMessage: 'Public Page',
          }),
        link: 'public-page',
        isVisible: true,
        eventTypes: [
          EventTypes.HYBRID.id,
          EventTypes.PHYSICAL.id,
          EventTypes.VIRTUAL.id,
        ],
        order: 3,
      },
      {
        id: 3,
        key: 'breakout-room',
        title: (intl) =>
          intl.formatMessage({
            description: 'Breakout Room',
            defaultMessage: 'Breakout Room',
          }),
        link: 'breakout-room',
        isVisible: true,
        eventTypes: [EventTypes.HYBRID.id, EventTypes.VIRTUAL.id],
        order: 4,
      },
      {
        id: 7,
        key: 'sponsor-banner',
        title: (intl) =>
          intl.formatMessage({
            description: 'Sponsor Banner',
            defaultMessage: 'Sponsor Banner',
          }),
        link: 'sponsor-banner',
        isVisible: true,
        eventTypes: [EventTypes.HYBRID.id, EventTypes.VIRTUAL.id],
        order: 5,
      },
      {
        id: 4,
        key: 'symposium',
        title: (intl) =>
          intl.formatMessage({
            description: 'Symposium',
            defaultMessage: 'Symposium',
          }),
        link: 'symposium',
        isVisible: true,
        eventTypes: [
          EventTypes.HYBRID.id,
          EventTypes.PHYSICAL.id,
          EventTypes.VIRTUAL.id,
        ],
        order: 6,
      },
      {
        id: 8,
        key: 'sponsor-list',
        title: (intl) =>
          intl.formatMessage({
            description: 'SponSponsored Participationst',
            defaultMessage: 'Sponsored Participations',
          }),
        link: 'sponsor-list',
        isVisible: true,
        eventTypes: [
          EventTypes.HYBRID.id,
          EventTypes.PHYSICAL.id,
          EventTypes.VIRTUAL.id,
        ],
        order: 7,
      },

      // {
      //   id: 10,
      //   key: 'sponsor-packages',
      //   title: (intl) =>
      //     intl.formatMessage({
      //       description: 'packages',
      //       defaultMessage: 'Packages',
      //     }),
      //   link: 'sponsor-packages',
      //   isVisible: false,
      //   eventTypes: [
      //     EventTypes.HYBRID.id,
      //     EventTypes.PHYSICAL.id,
      //     EventTypes.VIRTUAL.id,
      //   ],
      // },
      // {
      //   id: 11,
      //   key: 'symposium-services',
      //   title: (intl) =>
      //     intl.formatMessage({
      //       description: 'Symposium Services',
      //       defaultMessage: 'Symposium Services',
      //     }),
      //   link: 'symposium-services',
      //   isVisible: true,
      //   eventTypes: [
      //     EventTypes.HYBRID.id,
      //     EventTypes.PHYSICAL.id,
      //     EventTypes.VIRTUAL.id,
      //   ],
      // },
    ],
  },
  participation: {
    label: (intl) =>
      intl.formatMessage({
        description: 'services',
        defaultMessage: 'Services',
      }),
    keyName: 'services',
    items: [
      {
        id: 2,
        key: 'other-services',
        title: (intl) =>
          intl.formatMessage({
            description: 'Other Stand Services',
            // defaultMessage: 'Other Stand Services',
            defaultMessage: 'Stand Services',
          }),
        link: 'other-services',
        hideFrontoffice: false,
        isVisible: true,
        eventTypes: [EventTypes.HYBRID.id, EventTypes.PHYSICAL.id],
        order: 8,
      },
      {
        id: 10,
        key: 'symposium-services',
        title: (intl) =>
          intl.formatMessage({
            description: 'Symposium Services',
            defaultMessage: 'Symposium Services',
          }),
        link: 'symposium-services',
        isVisible: true,
        eventTypes: [
          EventTypes.HYBRID.id,
          EventTypes.PHYSICAL.id,
          EventTypes.VIRTUAL.id,
        ],
        order: 9,
      },
      {
        id: 9,
        key: 'sponsor-staff',
        title: (intl) =>
          intl.formatMessage({
            description: 'Sponsor Staff',
            defaultMessage: 'Sponsor Staff',
          }),
        link: 'sponsor-staff',
        isVisible: true,
        eventTypes: [
          EventTypes.HYBRID.id,
          EventTypes.PHYSICAL.id,
          EventTypes.VIRTUAL.id,
        ],
        order: 10,
      },
    ],
  },
  /* participation: {
      label: 'Participation',
      items: [
        {
          id: 1,
          title: 'Sign-Up',
          link: 'sign-up',
        },
      ],
    }, */
});

const SponsorServicesSubMenu = () => [
  { id: 1, title: 'Instructions', link: 'instructions' },
  { id: 2, title: 'Documents', link: 'documents' },
  { id: 3, title: 'Specifications', link: 'specifications' },
  { id: 4, title: 'Purchases Synoptic', link: 'purchases' },
];

const AgendaSessionTypes = {
  symposium: 'Symposium',
  abstract: 'Abstract',
  webinar: 'Webinar',
  custom: 'Custom',
};

const AgendaFixedRoles = [
  { id: 1, name: 'Presenter', frontofficeName: 'Presenter' },
  { id: 2, name: 'Moderator', frontofficeName: 'Moderator' },
  { id: 3, name: 'Chair', frontofficeName: 'Chair' },
  { id: 4, name: 'Facilitator', frontofficeName: 'Facilitator' },
  { id: 5, name: 'Speaker', frontofficeName: 'Speaker' },
  { id: 6, name: 'Author', frontofficeName: 'Author' },
];

const AgendaContextTypes = {
  ctx1: 'Scientific',
  ctx2: 'No Scientific',
};

const EventRoomTypes = {
  virtual: 'Virtual',
  physical: 'Physical',
  hybrid: 'Hybrid',
};

const SponsorBannerPagesTemplate = {
  vatRate: 0,
  bannerPages: [
    {
      name: 'Page A',
      image: SponsorBannerPageA,
      bannerAreas: [
        {
          name: 'A1',
          price: 500,
          isExclusive: false,
        },
        {
          name: 'A2',
          price: 500,
          isExclusive: false,
        },
        {
          name: 'A3',
          price: 500,
          isExclusive: false,
        },
      ],
    },
    {
      name: 'Page B',
      image: SponsorBannerPageB,
      bannerAreas: [
        {
          name: 'B1',
          price: 500,
          isExclusive: false,
        },
        {
          name: 'B2',
          price: 500,
          isExclusive: false,
        },
        {
          name: 'B3',
          price: 500,
          isExclusive: false,
        },
        {
          name: 'B4',
          price: 500,
          isExclusive: false,
        },
        {
          name: 'B5',
          price: 500,
          isExclusive: false,
        },
      ],
    },
  ],
};

const ProductType = {
  registration: 'REGISTRATION',
  agencyPrepaidBudget: 'AGENCY_PREPAID_BUDGET', // deprecated to remove
  agencyPrepaidGroup: 'AGENCY_PREPAID_GROUP', // deprecated to remove
  agencyGroupPrepaid: 'AGENCY_GROUP_PREPAID',
  agencyGroup: 'AGENCY_GROUP',
  scientificEvents: 'SCIENTIFIC_EVENT',
  socialEvents: 'SOCIAL_EVENT',
  reservedScientificEvents: 'RESERVED_SCIENTIFIC_EVENT',
  reservedSocialEvents: 'RESERVED_SOCIAL_EVENT',
  allotment: 'ALLOTMENT',
  penalties: 'PENALTIES',
};

const ProductTypes = {
  REGISTRATION: {
    key: ProductType.registration,
    label: (intl) =>
      intl.formatMessage({
        description: 'registration',
        defaultMessage: 'Registration',
      }),
  },
  AGENCY_GROUP_PREPAID: {
    key: ProductType.agencyGroupPrepaid,
    label: (intl) =>
      intl.formatMessage({
        description: 'agency group prepaid',
        defaultMessage: 'Agency Group Prepaid',
      }),
  },
  AGENCY_GROUP: {
    key: ProductType.agencyGroup,
    label: (intl) =>
      intl.formatMessage({
        description: 'agency group',
        defaultMessage: 'Agency Group',
      }),
  },
  SCIENTIFIC_EVENT: {
    key: ProductType.scientificEvents,
    label: (intl) =>
      intl.formatMessage({
        description: 'scientific events',
        defaultMessage: 'Scientific Events',
      }),
  },
  SOCIAL_EVENT: {
    key: ProductType.socialEvents,
    label: (intl) =>
      intl.formatMessage({
        description: 'social events',
        defaultMessage: 'Social Events',
      }),
  },
  RESERVED_SCIENTIFIC_EVENT: {
    key: ProductType.reservedScientificEvents,
    label: (intl) =>
      intl.formatMessage({
        description: 'reserved scientific events',
        defaultMessage: 'Reserved Scientific Events',
      }),
  },
  RESERVED_SOCIAL_EVENT: {
    key: ProductType.reservedSocialEvents,
    label: (intl) =>
      intl.formatMessage({
        description: 'reserved social events',
        defaultMessage: 'Reserved Social Events',
      }),
  },
  ALLOTMENT: {
    key: ProductType.allotment,
    label: (intl) =>
      intl.formatMessage({
        description: 'allotment',
        defaultMessage: 'Allotment',
      }),
  },
  PENALTIES: {
    key: ProductType.penalties,
    label: (intl) =>
      intl.formatMessage({
        description: 'penalties',
        defaultMessage: 'Penalties',
      }),
  },
};

const PaymentTypes = {
  BankTransfer: 'bankTransfer',
  CreditCard: 'creditCard',
  Free: 'free',
};

const PaymentTypesWithTranslations = {
  BANK_TRANSFER: {
    key: PaymentTypes.BankTransfer,
    id: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'bank transfer',
        defaultMessage: 'Bank Transfer',
      }),
    isOptionForGateway: true,
  },
  CREDIT_CARD: {
    key: PaymentTypes.CreditCard,
    id: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'credit card',
        defaultMessage: 'Credit Card',
      }),
    isOptionForGateway: true,
  },
  FREE: {
    key: PaymentTypes.Free,
    id: 2,
    label: (intl) =>
      intl.formatMessage({
        description: 'free',
        defaultMessage: 'Free',
      }),
    isOptionForGateway: false,
  },
  BOTH: {
    key: 'both',
    id: 3,
    label: (intl) =>
      intl.formatMessage({
        description: 'both',
        defaultMessage: 'Bank Transfer/Credit Card',
      }),
    isOptionForGateway: true,
  },
};

// do NOT change order is used in SponsorPayment
const PaymentGateway = [
  {
    key: 'MONETAONLINE',
    id: 1,
    label: 'Nexi',
  },
  {
    key: 'INGENICO',
    id: 2,
    label: 'Ingenico',
  },
  {
    key: 'GPWEBPAY',
    id: 3,
    label: 'Gp Webpay',
  },
];

const AgendaSessionLiveReactions = {
  ThumbsUp: { id: 'thumbsup', emoji: ['👍'] },
  Smile: { id: 'smile', emoji: ['😄'] },
  Heart: { id: 'heart', emoji: ['🧡'] },
  Applause: { id: 'applause', emoji: ['👏'] },
};

const AgendaSpeechesTypologies = {
  ABSTRACT: 'Abstract',
  MAIN: 'Main',
  DISCUSSION: 'Discussion',
};

const AgendaSpeechesTitleDescriptions = {
  GENERAL_DISCUSSION: 'General Discussion',
  LECTURE: 'Lecture',
};

const OtherStandServiceItemType = {
  unitaryCost: {
    id: 'unitaryCost',
    label: (intl) =>
      intl.formatMessage({
        description: 'Unitary Cost',
        defaultMessage: 'Unitary Cost',
      }),
  },
  singleChoice: {
    id: 'singleChoice',
    label: (intl) =>
      intl.formatMessage({
        description: 'Single Choice',
        defaultMessage: 'Single Choice',
      }),
  },
  doubleCost: {
    id: 'doubleCost',
    label: (intl) =>
      intl.formatMessage({
        description: 'Double Cost',
        defaultMessage: 'Double Cost',
      }),
  },
  typeTextService: {
    id: 'typeTextService',
    label: (intl) =>
      intl.formatMessage({
        description: 'Text Service',
        defaultMessage: 'Text Service',
      }),
  },
};

const SponsorServiceItemType = {
  unitaryCost: {
    id: 'unitaryCost',
    label: (intl) =>
      intl.formatMessage({
        description: 'Unitary Cost',
        defaultMessage: 'Unitary Cost',
      }),
  },
  singleChoice: {
    id: 'singleChoice',
    label: (intl) =>
      intl.formatMessage({
        description: 'Single Choice',
        defaultMessage: 'Single Choice',
      }),
  },
  doubleCost: {
    id: 'doubleCost',
    label: (intl) =>
      intl.formatMessage({
        description: 'Double Cost',
        defaultMessage: 'Double Cost',
      }),
  },
  typeTextService: {
    id: 'typeTextService',
    label: (intl) =>
      intl.formatMessage({
        description: 'Text Service',
        defaultMessage: 'Text Service',
      }),
  },
};
const FieldControlTypes = [
  {
    id: 'selectautocomplete',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type autocomplete option',
        defaultMessage: 'Autocomplete',
      }),
    withOptions: true,
  },
  {
    id: 'checkbox',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type radio option',
        defaultMessage: 'Checkbox',
      }),
    withOptions: false,
  },
  {
    id: 'date',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type date option',
        defaultMessage: 'Date',
      }),
    withOptions: false,
  },
  {
    id: 'multiselect',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type multiselect option',
        defaultMessage: 'Multiselect',
      }),
    withOptions: true,
  },
  {
    id: 'number',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type number option',
        defaultMessage: 'Number',
      }),
    withOptions: false,
  },
  {
    id: 'radio',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type radio option',
        defaultMessage: 'Radio',
      }),
    withOptions: true,
  },
  {
    id: 'select',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type select option',
        defaultMessage: 'Select',
      }),
    withOptions: true,
  },

  {
    id: 'text',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type text option',
        defaultMessage: 'Text',
      }),
    withOptions: false,
  },
  {
    id: 'upload',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type upload option',
        defaultMessage: 'Upload',
      }),
    withOptions: false,
  },
  {
    id: 'autocompletecity',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type autocomplete city option',
        defaultMessage: 'Acutocomplete City',
      }),
    withOptions: false,
  },
  {
    id: 'autocompletecountries',
    label: (intl) =>
      intl.formatMessage({
        description: 'control type autocomplete country option',
        defaultMessage: 'Acutocomplete countries',
      }),
    withOptions: false,
  },
  // {
  //   id: 'autocompleterigion',
  //   label: (intl) =>
  //     intl.formatMessage({
  //       description: 'control type autocomplete rigion option',
  //       defaultMessage: 'Acutocomplete rigion',
  //     }),
  //   withOptions: false,
  // },
];

const FacultyResponseStatus = {
  NOT_COMPILED: 'notCompiled',
  DRAFT: 'draft',
  PUBLISHED: 'published',
  REJECTED: 'rejected',
};

const FacultyPageStatus = {
  NotCompiled: {
    id: FacultyResponseStatus.NOT_COMPILED,
    label: (intl) =>
      intl.formatMessage({
        description: 'not compiled status page faculty',
        defaultMessage: 'Not Compiled',
      }),
  },
  Draft: {
    id: FacultyResponseStatus.DRAFT,
    label: (intl) =>
      intl.formatMessage({
        description: 'draft status page faculty',
        defaultMessage: 'Draft',
      }),
  },
  Confirmed: {
    id: FacultyResponseStatus.PUBLISHED,
    label: (intl) =>
      intl.formatMessage({
        description: 'confirmed status page faculty',
        defaultMessage: 'Confirmed',
      }),
  },
  Rejected: {
    id: FacultyResponseStatus.REJECTED,
    label: (intl) =>
      intl.formatMessage({
        description: 'rejected status page faculty',
        defaultMessage: 'Rejected',
      }),
  },
};

const ParticipantStatus = [
  {
    key: 'paymentpending',
    value: 'paymentpending',
    id: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'participant payment pending status',
        defaultMessage: 'Payment pending',
      }),
  },
  {
    key: 'suspended',
    value: 'suspended',
    id: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'suspended',
        defaultMessage: 'Suspended',
      }),
  },
  {
    key: 'canceled',
    value: 'canceled',
    id: 2,
    label: (intl) =>
      intl.formatMessage({
        description: 'canceled',
        defaultMessage: 'Canceled',
      }),
  },
  {
    key: 'invited',
    value: 'invited',
    id: 4,
    label: (intl) =>
      intl.formatMessage({
        description: 'invited',
        defaultMessage: 'Invited',
      }),
  },
  {
    key: 'registered',
    value: 'registered',
    id: 5,
    label: (intl) =>
      intl.formatMessage({
        description: 'registered',
        defaultMessage: 'Registered',
      }),
  },
  {
    key: 'effective',
    value: 'effective',
    id: 6,
    label: (intl) =>
      intl.formatMessage({
        description: 'effective',
        defaultMessage: 'Effective',
      }),
  },
  {
    key: 'checkinAttendant',
    value: 'checkinAttendant',
    id: 7,
    label: (intl) =>
      intl.formatMessage({
        description: 'check-in/attendant',
        defaultMessage: 'Check-in/Attendant',
      }),
  },
  {
    key: 'confirmed',
    value: 'confirmed',
    id: 8,
    label: (intl) =>
      intl.formatMessage({
        description: 'confirmed',
        defaultMessage: 'Confirmed',
      }),
  },
  {
    key: 'inprogram',
    value: 'inprogram',
    id: 9,
    label: (intl) =>
      intl.formatMessage({
        description: 'in program',
        defaultMessage: 'In program',
      }),
  },
];

const participantStatuses = {
  PAYMENT_PENDING: ParticipantStatus[0],
  SUSPENDED: ParticipantStatus[1],
  CANCELED: ParticipantStatus[2],
  INVITED: ParticipantStatus[3],
  REGISTERED: ParticipantStatus[4],
  EFFECTIVE: ParticipantStatus[5],
  CHECKIN_ATTENDANT: ParticipantStatus[6],
  CONFIRMED: ParticipantStatus[7],
  IN_PROGRAM: ParticipantStatus[8],
};

const ParticipantState = [
  {
    key: 'To invite',
    id: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'to invite',
        defaultMessage: 'To invite',
      }),
  },
  {
    key: 'Invited',
    id: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'invited',
        defaultMessage: 'Invited',
      }),
  },
];

const BuyersStatus = {
  INVITED: {
    key: 'invited',
    value: 'invited',
    id: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'invited status',
        defaultMessage: 'Invited',
      }),
  },
  TOINVITE: {
    key: 'toInvite',
    value: 'toInvite',
    id: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'to invite status',
        defaultMessage: 'To invite',
      }),
  },
};

const PaymentChildStatuses = {
  DONE: {
    key: 'done',
    label: (intl) =>
      intl.formatMessage({
        description: 'payment child status done label',
        defaultMessage: 'Done',
      }),
  },
  PENDING: {
    key: 'pending',
    label: (intl) =>
      intl.formatMessage({
        description: 'payment child status pending label',
        defaultMessage: 'Pending',
      }),
  },
};

const PaymentStatuses = {
  DONE: {
    key: 'done',
    label: (intl) =>
      intl.formatMessage({
        description: 'payment status done label',
        defaultMessage: 'Done',
      }),
  },
  PENDING: {
    key: 'pending',
    label: (intl) =>
      intl.formatMessage({
        description: 'payment status pending label',
        defaultMessage: 'Pending',
      }),
  },
  CANCELLED: {
    //credit slip?
    key: 'cancelled',
    label: (intl) =>
      intl.formatMessage({
        description: 'payment status cancelled label',
        defaultMessage: 'Cancelled',
      }),
  },
  PARTIAL: {
    key: 'partial',
    label: (intl) =>
      intl.formatMessage({
        description: 'payment status partial label',
        defaultMessage: 'Partial',
      }),
  },
  REFOUNDED: {
    key: 'refounded',
    label: (intl) =>
      intl.formatMessage({
        description: 'payment status refounded label',
        defaultMessage: 'Refounded',
      }),
  },
};

const HotelTreatments = {
  B_B: {
    key: 'bed&breakfast',
    label: (intl) =>
      intl.formatMessage({
        description: 'hotel treatments breakfast label',
        defaultMessage: 'Bed&Breakfast',
      }),
  },
  FULL_CREDIT: {
    key: 'fullCredit',
    label: (intl) =>
      intl.formatMessage({
        description: 'hotel treatments fullCredit label',
        defaultMessage: 'Full credit',
      }),
  },
  HALF_BOARD: {
    key: 'halfBoard',
    label: (intl) =>
      intl.formatMessage({
        description: 'hotel treatments halfBoard label',
        defaultMessage: 'Half board',
      }),
  },
  FULL_BOARD: {
    key: 'fullBoard',
    label: (intl) =>
      intl.formatMessage({
        description: 'hotel treatments fullBoard label',
        defaultMessage: 'Full board',
      }),
  },
};

const CancellationStatuses = {
  SOLVED: {
    key: 'solved',
    label: (intl) =>
      intl.formatMessage({
        description: 'cancellation solved label',
        defaultMessage: 'Solved',
      }),
  },
  PENDING: {
    key: 'toSolve',
    label: (intl) =>
      intl.formatMessage({
        description: 'cancellation to solve label',
        defaultMessage: 'To solve',
      }),
  },
};

const ConflictStatuses = {
  SOLVED: {
    key: 'solved',
    label: (intl) =>
      intl.formatMessage({
        description: 'cancellation solved label',
        defaultMessage: 'Solved',
      }),
  },
  TOSOLVE: {
    key: 'toSolve',
    label: (intl) =>
      intl.formatMessage({
        description: 'cancellation to solve label',
        defaultMessage: 'To solve',
      }),
  },
};

const AbstractFieldContext = {
  AbstractTemplate: `abstractTemplate`,
  Author: `Author`,
  Presenter: `Presenter`,
  Abstract: `abstract`,
};

const ModificationRequestTypes = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  NAME_CHANGE: 'NAME_CHANGE',
};

const ModificationRequestStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

const IssueStatus = [
  {
    key: 'active',
    id: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'active',
        defaultMessage: 'Active',
      }),
  },
  {
    key: 'workinprogress',
    id: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'work in progress',
        defaultMessage: 'Work in progress',
      }),
  },
  {
    key: 'standby',
    id: 2,
    label: (intl) =>
      intl.formatMessage({
        description: 'stand-by',
        defaultMessage: 'Stand-by',
      }),
  },
  {
    key: 'closed',
    id: 3,
    label: (intl) =>
      intl.formatMessage({
        description: 'closed',
        defaultMessage: 'Closed',
      }),
  },
];

const AbstractStatus = {
  DRAFT: {
    id: 'draft',
    label: (intl) =>
      intl.formatMessage({
        description: 'draft',
        defaultMessage: 'Draft',
      }),
  },
  PUBLISHED: {
    id: 'published',
    label: (intl) =>
      intl.formatMessage({
        description: 'published',
        defaultMessage: 'Published',
      }),
  },
  READYFORACCEPTANCE: {
    id: 'readyForAcceptance',
    label: (intl) =>
      intl.formatMessage({
        description: 'readyForAcceptance',
        defaultMessage: 'Ready For Acceptance',
      }),
  },
  ACCEPTED: {
    id: 'accepted',
    label: (intl) =>
      intl.formatMessage({
        description: 'accepted',
        defaultMessage: 'Accepted',
      }),
  },
  REJECTED: {
    id: 'rejected',
    label: (intl) =>
      intl.formatMessage({
        description: 'rejected',
        defaultMessage: 'Rejected',
      }),
  },
  DOUBLE_SUBMISSION: {
    id: 'double_submission',
    label: (intl) =>
      intl.formatMessage({
        description: 'double submission status abstract',
        defaultMessage: 'Double Submission',
      }),
  },
};

const GrantStatuses = {
  TO_BE_PROCESSED: {
    id: 'tobeprocessed',
    color: '#FFF3B8',
    label: (intl) =>
      intl.formatMessage({
        description: 'grant to be processed status',
        defaultMessage: 'To Be Processed',
      }),
  },
  PROCESSED: {
    id: 'processed',
    color: '#FFF3B8',
    label: (intl) =>
      intl.formatMessage({
        description: 'grant processed status',
        defaultMessage: 'Processed',
      }),
  },
  ACCEPTED: {
    id: 'accepted',
    color: '#BEF6BE',
    label: (intl) =>
      intl.formatMessage({
        description: 'grant accepted status',
        defaultMessage: 'Accepted',
      }),
  },
  REJECTED: {
    id: 'rejected',
    color: '#FFCEDB',
    label: (intl) =>
      intl.formatMessage({
        description: 'grant rejected status',
        defaultMessage: 'Rejected',
      }),
  },
  //theese last two states are only logical states, we never write them on database
  SUCCESSFUL: {
    id: 'successful',
    color: '#BEF6BE',
    label: (intl) =>
      intl.formatMessage({
        description: 'grant successful status',
        defaultMessage: 'Successful',
      }),
  },
  REFUSED: {
    id: 'refused',
    color: '#FFCEDB',
    label: (intl) =>
      intl.formatMessage({
        description: 'grant refused status',
        defaultMessage: 'Refused',
      }),
  },
};

const GrantType = {
  EDUCATIONAL: {
    key: 'educational',
    id: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'Educational',
        defaultMessage: 'Educational',
      }),
  },
  ABSTRACT: {
    key: 'abstract',
    id: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'Abstract',
        defaultMessage: 'Abstract',
      }),
  },
};
const AdditionalServicesServiceTypology = {
  scientific: {
    id: 'scientific',
    productServiceType: ProductType.scientificEvents,
    label: (intl) =>
      intl.formatMessage({
        description: 'Scientific event',
        defaultMessage: 'Scientific event',
      }),
  },
  social: {
    id: 'social',
    productServiceType: ProductType.socialEvents,
    label: (intl) =>
      intl.formatMessage({
        description: 'Social Event',
        defaultMessage: 'Social Event',
      }),
  },
};

const rules = {
  YESNO: {
    id: 'yesno',
    label: (intl) =>
      intl.formatMessage({
        description: 'Yes/no',
        defaultMessage: 'Yes/no',
      }),
  },
  QUANTITY: {
    id: 'quantity',
    label: (intl) =>
      intl.formatMessage({
        description: 'Quantity',
        defaultMessage: 'Quantity',
      }),
  },
  ONLYONE: {
    id: 'onlyone',
    label: (intl) =>
      intl.formatMessage({
        description: 'Only one on the list',
        defaultMessage: 'Only one on the list',
      }),
  },
};

const DestinationTravelTypes = {
  AIRPORT: {
    id: 'airport',
    label: (intl) =>
      intl.formatMessage({
        description: 'Airport',
        defaultMessage: 'Airport',
      }),
  },
  CONGRESS: {
    id: 'congress',
    label: (intl) =>
      intl.formatMessage({
        description: 'Congress Centre',
        defaultMessage: 'Congress Centre',
      }),
  },
  STATION: {
    id: 'station',
    label: (intl) =>
      intl.formatMessage({
        description: 'Railway Station',
        defaultMessage: 'Railway Station',
      }),
  },
  HOTEL: {
    id: 'hotel',
    label: (intl) =>
      intl.formatMessage({
        description: 'Hotel',
        defaultMessage: 'Hotel',
      }),
  },
  OTHER: {
    id: 'other',
    label: (intl) =>
      intl.formatMessage({
        description: 'Other',
        defaultMessage: 'Other',
      }),
  },
};

const TravelTypes = {
  AIRPLANE: {
    id: 'airplane',
    label: (intl) =>
      intl.formatMessage({
        description: 'Airplane',
        defaultMessage: 'Airplane',
      }),
  },
  TRAIN: {
    id: 'train',
    label: (intl) =>
      intl.formatMessage({
        description: 'Train',
        defaultMessage: 'Train',
      }),
  },
  BUS: {
    id: 'bus',
    label: (intl) =>
      intl.formatMessage({
        description: 'Bus',
        defaultMessage: 'Bus',
      }),
  },
  PERSONALTRANSPORT: {
    id: 'personalTransport',
    label: (intl) =>
      intl.formatMessage({
        description: 'Personal transport',
        defaultMessage: 'Personal transport',
      }),
  },
  OTHER: {
    id: 'other',
    label: (intl) =>
      intl.formatMessage({
        description: 'Other',
        defaultMessage: 'Other',
      }),
  },
  SHIP: {
    id: 'ship',
    label: (intl) =>
      intl.formatMessage({
        description: 'Ship',
        defaultMessage: 'Ship',
      }),
  },
  GROUPFLIGHT: {
    id: 'groupFlight',
    label: (intl) =>
      intl.formatMessage({
        description: 'Group flight',
        defaultMessage: 'Group flight',
      }),
  },
};

const InvoiceToType = {
  COMPANY: {
    id: 'company',
    label: (intl) =>
      intl.formatMessage({
        description: 'Company',
        defaultMessage: 'Company',
      }),
  },
  INDIVIDUAL: {
    id: 'individual',
    label: (intl) =>
      intl.formatMessage({
        description: 'Individual',
        defaultMessage: 'Individual',
      }),
  },
};

const DocumentType = {
  INVOICE: {
    id: 'invoice',
    label: (intl) =>
      intl.formatMessage({
        description: 'Invoice',
        defaultMessage: 'Invoice',
      }),
  },
  CREDIT_NOTE: {
    id: 'creditNote',
    label: (intl) =>
      intl.formatMessage({
        description: 'Credit Note',
        defaultMessage: 'Credit Note',
      }),
  },
};

const TicketRequest = {
  PROCESSED: {
    id: 'processed',
    label: (intl) =>
      intl.formatMessage({
        description: 'Processed',
        defaultMessage: 'Processed',
      }),
  },
  TOPROCESS: {
    id: 'toProcess',
    label: (intl) =>
      intl.formatMessage({
        description: 'To process',
        defaultMessage: 'To process',
      }),
  },
};

const PapersTypologies = {
  GENERIC: {
    id: 'generic',
    label: (intl) =>
      intl.formatMessage({
        description: 'Generic',
        defaultMessage: 'Generic',
      }),
    path: 'certificates/generic',
    paperType: 'certificate',
  },
  WELCOME: {
    id: 'welcome',
    label: (intl) =>
      intl.formatMessage({
        description: 'Welcome',
        defaultMessage: 'Welcome',
      }),
    path: 'welcome/add',
    paperType: 'welcome',
  },
  NOMINAL: {
    id: 'nominal',
    label: (intl) =>
      intl.formatMessage({
        description: 'Nominal',
        defaultMessage: 'Nominal',
      }),
    path: 'certificates/nominal',
    paperType: 'certificate',
  },
  ABSTRACT: {
    id: 'abstract',
    label: (intl) =>
      intl.formatMessage({
        description: 'Abstract',
        defaultMessage: 'Abstract',
      }),
    path: 'certificates/abstract',
    // paperType: 'certificateWithAbstract',
    paperType: 'certificate',
  },
  PAX: {
    id: 'pax',
    label: (intl) =>
      intl.formatMessage({
        description: 'pax template',
        defaultMessage: 'Visa Template',
      }),
    path: 'visa/pax-template',
    paperType: 'visa',
  },
  EMBASSY: {
    id: 'embassy',
    label: (intl) =>
      intl.formatMessage({
        description: 'Generic',
        defaultMessage: 'Embassy Template',
      }),
    path: 'visa/embassy-template',
    paperType: 'visa',
  },
};

const BadgesFontFamilies = {
  COURIER: {
    id: 'Courier',
    bold: 'Courier-Bold',
    italic: 'Courier-Oblique',
    bolditalic: 'Courier-BoldOblique',
  },
  Helvetica: {
    id: 'Helvetica',
    bold: 'Helvetica-Bold',
    italic: 'Helvetica-Oblique',
    bolditalic: 'Helvetica-BoldOblique',
  },
  TIME_ROMAN: {
    id: 'Times-Roman',
    bold: 'Times-Bold',
    italic: 'Times-Italic',
    bolditalic: 'Times-BoldItalic',
  },
};

const BadgesFontStyles = {
  BOLD: {
    id: 'bold',
    label: (intl) =>
      intl.formatMessage({
        description: 'styleBold label',
        defaultMessage: 'Bold',
      }),
  },
  ITALIC: {
    id: 'italic',
    label: (intl) =>
      intl.formatMessage({
        description: 'styleItalic label',
        defaultMessage: 'Italic',
      }),
  },
  // UNDERLINED: {
  //   id: 'underlined',
  //   label: (intl) =>
  //     intl.formatMessage({
  //       description: 'styleUnderlined label',
  //       defaultMessage: 'Underlined',
  //     }),
  // },
};

const BadgesParagraphs = {
  LEFT_ALIGN: {
    id: 'left_aligned',
    label: (intl) =>
      intl.formatMessage({
        description: 'left-aligned label',
        defaultMessage: 'Left-aligned',
      }),
  },
  RIGHT_ALIGN: {
    id: 'right_aligned',
    label: (intl) =>
      intl.formatMessage({
        description: 'right-aligned label',
        defaultMessage: 'Right-aligned',
      }),
  },
  CENTER_ALIGN: {
    id: 'center_aligned',
    label: (intl) =>
      intl.formatMessage({
        description: 'centerialigned label',
        defaultMessage: 'Center-aligned',
      }),
  },
};

const BadgesFieldTypes = {
  QRCODE: {
    id: 'qrcode',
    label: (intl) =>
      intl.formatMessage({
        description: 'QR Code label',
        defaultMessage: 'QR Code',
      }),
  },
  BARCODE: {
    id: 'barcode',
    label: (intl) =>
      intl.formatMessage({
        description: 'Barcode label',
        defaultMessage: 'Barcode',
      }),
  },
};

const BadgesBarcodeRotation = {
  NORMAL: {
    id: 'N',
    label: (intl) =>
      intl.formatMessage({
        description: ' Normal (not rotated) label',
        defaultMessage: ' Normal (not rotated)',
      }),
  },
  RIGHT: {
    id: 'R',
    label: (intl) =>
      intl.formatMessage({
        description: 'Clockwise (right) 90 degree rotation label',
        defaultMessage: 'Clockwise (right) 90 degree rotation',
      }),
  },
  LEFT: {
    id: 'L',
    label: (intl) =>
      intl.formatMessage({
        description: 'Counter-clockwise (left) 90 degree rotation label',
        defaultMessage: 'Counter-clockwise (left) 90 degree rotation',
      }),
  },
  INVERTED: {
    id: 'I',
    label: (intl) =>
      intl.formatMessage({
        description: 'Inverted 180 degree rotation label',
        defaultMessage: 'Inverted 180 degree rotation',
      }),
  },
};

const Templates = {
  FRONTEND: 'FRONTEND',
  BACKEND: 'BACKEND',
};

const currencies = ['EUR', 'USD', 'AUD'];

const TemplateButtonVariants = {
  OUTLINED: 'outlined',
  CONTAINED: 'contained',
  TEXT: 'text',
};

const TwitterPostsStatuses = [
  {
    key: 'published',
    id: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'published',
        defaultMessage: 'Published',
      }),
  },
  {
    key: 'hidden',
    id: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'hidden',
        defaultMessage: 'Hidden',
      }),
  },
  {
    key: 'pending',
    id: 2,
    label: (intl) =>
      intl.formatMessage({
        description: 'pending',
        defaultMessage: 'Pending',
      }),
  },
];

const EventHomepageItems = {
  WELCOME_MESSAGE: {
    id: 'welcome_message',
    databaseFlagName: 'homepageWelcomeMessageEnabled',
    label: (intl) =>
      intl.formatMessage({
        description:
          'event platform interface configuration welcome message label',
        defaultMessage: 'Welcome message',
      }),
    link: '',
  },
  MAP: {
    id: 'map',
    databaseFlagName: 'homepageMapEnabled',
    label: (intl) =>
      intl.formatMessage({
        description: 'event platform interface configuration map label',
        defaultMessage: 'Map',
      }),
    link: 'map',
  },
  ACTIVITY: {
    id: 'activity',
    databaseFlagName: 'homepageActivityEnabled',
    label: (intl) =>
      intl.formatMessage({
        description: 'event platform interface configuration activity label',
        defaultMessage: 'Activity',
      }),
    link: '',
  },
  SPONSOR: {
    id: 'sponsor',
    databaseFlagName: 'homepageSponsorEnabled',
    label: (intl) =>
      intl.formatMessage({
        description: 'event platform interface configuration sponsor label',
        defaultMessage: 'Sponsor',
      }),
    link: '',
  },
  AGENDA: {
    id: 'agenda',
    databaseFlagName: 'homepageAgendaEnabled',
    label: (intl) =>
      intl.formatMessage({
        description:
          'event platform interface configuration welcome message label',
        defaultMessage: 'Timetable/My Agenda',
      }),
    link: '',
  },
  SPEAKERS: {
    id: 'speakers',
    databaseFlagName: 'homepageSpeakersEnabled',
    label: (intl) =>
      intl.formatMessage({
        description: 'event platform interface configuration faculty label',
        defaultMessage: 'Faculty',
      }),
    link: '',
  },
  PRE_FOOTER: {
    id: 'pre_footer',
    databaseFlagName: 'homepagePrefooterEnabled',
    label: (intl) =>
      intl.formatMessage({
        description: 'event platform interface configuration pre footer label',
        defaultMessage: 'Pre footer',
      }),
    link: 'prefooter',
  },
};

const EventHomepageMapShapes = {
  RECT: {
    id: 'rect',
    label: (intl) =>
      intl.formatMessage({
        description:
          'event platform interface configuration map shape rectangle option',
        defaultMessage: 'Rectangle',
      }),
  },
  CIRCLE: {
    id: 'circle',
    label: (intl) =>
      intl.formatMessage({
        description:
          'event platform interface configuration map shape circle option',
        defaultMessage: 'Circle',
      }),
  },
  ELLIPSE: {
    id: 'ellipse',
    label: (intl) =>
      intl.formatMessage({
        description:
          'event platform interface configuration map shape ellipse option',
        defaultMessage: 'Ellipse',
      }),
  },
  POLYGON: {
    id: 'polygon',
    label: (intl) =>
      intl.formatMessage({
        description:
          'event platform interface configuration map shape polygon option',
        defaultMessage: 'Polygon',
      }),
  },
};

const EventHomepageActivitiesTypes = {
  SPONSORS: {
    id: 'sponsors',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage activities sponsors',
        defaultMessage: 'Sponsor',
      }),
    link: 'sponsors',
  },
  SESSIONS: {
    id: 'sessions',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage activities sessions',
        defaultMessage: 'Sessions',
      }),
    link: 'sessions',
  },
  CONTENTS: {
    id: 'contents',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage activities on-demand',
        defaultMessage: 'On-demand',
      }),
    link: 'contents',
  },
  LOUNGE_AREA: {
    id: 'lounge_area',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage activities lounge area',
        defaultMessage: 'Lounge area',
      }),
    link: 'lounge-area',
  },
  SPEAKERS: {
    id: 'speakers',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage activities faculty',
        defaultMessage: 'Faculty',
      }),
    link: 'speakers',
  },
  AGENDA: {
    id: 'agenda',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage activities my agenda',
        defaultMessage: 'My Agenda',
      }),
    link: 'agenda',
  },
};

const EventHomepageMapLinkTypes = {
  SPONSORS: {
    id: 'sponsors',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types sponsors',
        defaultMessage: 'Sponsor',
      }),
    link: 'sponsors',
  },
  SPEAKERS: {
    id: 'speakers',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types faculty',
        defaultMessage: 'Faculty',
      }),
    link: 'speakers',
  },
  VIRTUAL_BAG: {
    id: 'virtual_bag',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types virtual bag',
        defaultMessage: 'Virtual bag',
      }),
    link: 'virtual-bag',
  },
  INFO_PAGE: {
    id: 'info_page',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types info page',
        defaultMessage: 'Info page',
      }),
    link: 'general-info',
  },
  CONTACT_US: {
    id: 'contact_us',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types contact us',
        defaultMessage: 'Contact us',
      }),
    link: 'contact-page',
  },
  LOUNGE_AREA: {
    id: 'lounge_area',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types lounge area',
        defaultMessage: 'Lounge area',
      }),
    link: 'lounge-area',
  },
  CONTENTS: {
    id: 'contents',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types on-demand',
        defaultMessage: 'On-demand',
      }),
    link: 'contents',
  },
  SOCIAL_WALL: {
    id: 'social_wall',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types social wall',
        defaultMessage: 'Social Wall',
      }),
    hlink: 'social-wall',
  },
  E_POSTER: {
    id: 'e_poster',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types e-poster',
        defaultMessage: 'E-Poster',
      }),
    link: 'e-poster',
  },
  SESSIONS: {
    id: 'sessions',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types virtual bag',
        defaultMessage: 'Sessions',
      }),
    link: 'sessions',
  },
  AGENDA: {
    id: 'agenda',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types timetable/my agenda',
        defaultMessage: 'Timetable/My agenda',
      }),
    link: 'agenda',
  },
  SURVEY: {
    id: 'survey',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types survey link',
        defaultMessage: 'Survey',
      }),
    linkFn: (participationId) => `user/${participationId}/surveys`,
  },
  CERTIFICATIONS: {
    id: 'certifications',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types certifications link',
        defaultMessage: 'Certifications',
      }),
    linkFn: (participationId) => `user/${participationId}/certifications`,
  },
  CUSTOM: {
    id: 'custom',
    label: (intl) =>
      intl.formatMessage({
        description: 'event homepage map link types other link',
        defaultMessage: 'Other',
      }),
  },
};

const EventGenericMenuItems = {
  STYLE_SETTINGS: {
    key: 'style_settings',
    order: 11,
    label: (intl) =>
      intl.formatMessage({
        description: 'Style Settings',
        defaultMessage: 'Style Settings',
      }),
    // name: 'Style Settings',
    hasEye: true,
    hasActiveFlag: false,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: 'general-settings/style-settings',
    frontofficeLink: '', //when '' not shown on frontoffice header
  },
  REGISTRATION: {
    key: 'registration',
    order: 12,
    label: (intl) =>
      intl.formatMessage({
        description: 'Registration',
        defaultMessage: 'Registration',
      }),
    // name: 'Registration',
    hasEye: true,
    hasActiveFlag: false,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: 'general-settings/registration',
    frontofficeLink: '', //when '' not shown on frontoffice header
  },
  HOMEPAGE: {
    key: 'homepage',
    order: 0,
    label: (intl) =>
      intl.formatMessage({
        description: 'Homepage',
        defaultMessage: 'Homepage',
      }),
    // name: 'Homepage',
    hasEye: true,
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: 'general-settings/homepage',
    frontofficeLink: 'homepage',
  },
  ON_DEMAND: {
    key: 'on_demand',
    order: 4,
    label: (intl) =>
      intl.formatMessage({
        description: 'On-Demand',
        defaultMessage: 'On-Demand',
      }),
    // name: 'On-Demand',
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: '',
    frontofficeLink: 'contents',
  },
  SPONSOR: {
    key: 'sponsor',
    order: 2,
    label: (intl) =>
      intl.formatMessage({
        description: 'Sponsor / Exhibitor',
        defaultMessage: 'Sponsor / Exhibitor',
      }),
    // name: 'Sponsor / Exhibitor',
    hasActiveFlag: true,
    hasShowPremiumRanges: true,
    hasShowUpNext: false,
    backofficeLink: '',
    frontofficeLink: 'sponsors',
  },
  SPEAKERS: {
    key: 'speakers',
    order: 3,
    label: (intl) =>
      intl.formatMessage({
        description: 'Faculty',
        defaultMessage: 'Faculty',
      }),
    // name: 'Faculty',
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: '',
    frontofficeLink: 'speakers',
  },
  SESSIONS: {
    key: 'sessions',
    order: 13,
    label: (intl) =>
      intl.formatMessage({
        description: 'Sessions',
        defaultMessage: 'Sessions',
      }),
    // name: 'Sessions',
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: true,
    backofficeLink: '',
    frontofficeLink: 'sessions',
  },
  AGENDA: {
    key: 'agenda',
    order: 1,
    label: (intl) =>
      intl.formatMessage({
        description: 'Timetable / My Agenda',
        defaultMessage: 'Timetable / My Agenda',
      }),
    // name: 'Timetable / My Agenda',
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: '',
    frontofficeLink: 'agenda',
  },
  LOUNGE_AREA: {
    key: 'lounge_area',
    order: 7,
    label: (intl) =>
      intl.formatMessage({
        description: 'Lounge Area',
        defaultMessage: 'Lounge Area',
      }),
    // name: 'Lounge Area',
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: '',
    frontofficeLink: 'lounge-area',
  },
  INFO_PAGE: {
    key: 'info_page',
    order: 8,
    label: (intl) =>
      intl.formatMessage({
        description: 'Info Page',
        defaultMessage: 'Info Page',
      }),
    // name: 'Info Page',
    hasEye: true,
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: 'general-settings/general-info',
    frontofficeLink: 'general-info',
  },
  VIRTUAL_BAG: {
    key: 'virtual_bag',
    order: 10,
    label: (intl) =>
      intl.formatMessage({
        description: 'Virtual Bag',
        defaultMessage: 'Virtual Bag',
      }),
    // name: 'Virtual Bag',
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: '',
    frontofficeLink: 'virtual-bag',
  },
  E_POSTER: {
    key: 'e_poster',
    order: 6,
    label: (intl) =>
      intl.formatMessage({
        description: 'E-Poster',
        defaultMessage: 'E-Poster',
      }),
    // name: 'E-Poster',
    hasEye: true,
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: 'general-settings/e-poster',
    frontofficeLink: 'e-poster',
  },
  // CONTACT_US: {
  //   key: 'contact_us',
  //   order: 12,
  //   label: (intl) =>
  //     intl.formatMessage({
  //       description: 'Contact Us',
  //       defaultMessage: 'Contact Us',
  //     }),
  //   // name: 'Contact Us',
  //   hasEye: true,
  //   hasActiveFlag: true,
  //   hasShowPremiumRanges: false,
  //   hasShowUpNext: false,
  //   backofficeLink: '',
  //   frontofficeLink: 'contact-us',
  // },
  SOCIAL_WALL: {
    key: 'social_wall',
    order: 5,
    label: (intl) =>
      intl.formatMessage({
        description: 'Social Wall',
        defaultMessage: 'Social Wall',
      }),
    // name: 'Contact Us',
    hasEye: false,
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: '',
    frontofficeLink: 'social-wall',
  },
  FOOTER: {
    key: 'footer',
    order: 14,
    label: (intl) =>
      intl.formatMessage({
        description: 'Footer',
        defaultMessage: 'Footer',
      }),
    // name: 'Footer',
    hasEye: true,
    hasActiveFlag: true,
    hasShowPremiumRanges: false,
    hasShowUpNext: false,
    backofficeLink: 'general-settings/footer',
    frontofficeLink: '', //when '' not shown on frontoffice header
  },
};

const BreakoutRoomStatuses = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  PENDING: 'pending',
  CANCELED: 'canceled',
};

const EventEditPersonalDetailsTypes = {
  DISABLED: {
    id: 'disabled',
    label: (intl) =>
      intl.formatMessage({
        description: 'Edit personal details disabled label',
        defaultMessage: 'Disabled',
      }),
  },
  ONLY_ONCE: {
    id: 'only_once',
    label: (intl) =>
      intl.formatMessage({
        description: 'Edit personal details only once label',
        defaultMessage: 'Only once',
      }),
  },
  ALWAYS_EDITABLE: {
    id: 'always_editable',
    label: (intl) =>
      intl.formatMessage({
        description: 'Edit personal details always editable label',
        defaultMessage: 'Always editable',
      }),
  },
  DISABLED_AFTER_DATE: {
    id: 'disabled_after_date',
    label: (intl) =>
      intl.formatMessage({
        description: 'Edit personal details disabled after date label',
        defaultMessage: 'Disabled after date',
      }),
  },
};

const PrintOptions = {
  generalSessionCode: {
    key: 'generalSessionCode',
    label: (intl) =>
      intl.formatMessage({
        description: 'session code',
        defaultMessage: 'Session Code',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
    },
  },
  generalSessionTitle: {
    key: 'generalSessionTitle',
    label: (intl) =>
      intl.formatMessage({
        description: 'title label',
        defaultMessage: 'Title',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
    },
  },
  generalSessionDate: {
    key: 'generalSessionDate',
    label: (intl) =>
      intl.formatMessage({
        description: 'date label',
        defaultMessage: 'Date',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
    },
  },
  generalSessionStartEndTime: {
    key: 'generalSessionStartEndTime',
    label: (intl) =>
      intl.formatMessage({
        description: 'start - end time label',
        defaultMessage: 'Start - end time',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      position: [
        {
          key: 'toTheRightOfTheTitle',
          label: (intl) =>
            intl.formatMessage({
              description: 'to the right of the title',
              defaultMessage: 'To the right of the title',
            }),
        },
      ],
    },
  },
  generalSessionRoom: {
    key: 'generalSessionRoom',
    label: (intl) =>
      intl.formatMessage({
        description: 'room label',
        defaultMessage: 'Room',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
    },
  },
  generalSessionDescription: {
    key: 'generalSessionDescription',
    label: (intl) =>
      intl.formatMessage({
        description: 'description label',
        defaultMessage: 'Description ',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
    },
  },
  generalSessionSponsor: {
    key: 'generalSessionSponsor',
    label: (intl) =>
      intl.formatMessage({
        description: 'sponsor label',
        defaultMessage: 'Sponsor typlology',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
    },
  },
  subSessionBlockCode: {
    key: 'subSessionBlockCode',
    label: (intl) =>
      intl.formatMessage({
        description: 'code subsession label',
        defaultMessage: 'Code subsession',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      position: [
        {
          key: 'underTheModerators',
          label: (intl) =>
            intl.formatMessage({
              description: 'under the moderators label',
              defaultMessage: 'Under the moderators (Valid only if present)',
            }),
        },
      ],
    },
  },
  subSessionBlockTitle: {
    key: 'subSessionBlockTitle',
    label: (intl) =>
      intl.formatMessage({
        description: 'title subsession label',
        defaultMessage: 'Title subsession',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      // position: [
      //   {
      //     key: 'underTheModerators',
      //     label: (intl) =>
      //       intl.formatMessage({
      //         description: 'under the moderators label',
      //         defaultMessage: 'Under the moderators (Valid only if present)',
      //       }),
      //   },
      // ],
    },
  },
  subSessionBlockDescription: {
    key: 'subSessionBlockDescription',
    label: (intl) =>
      intl.formatMessage({
        description: 'description title label',
        defaultMessage: 'Description title',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      position: [
        {
          key: 'toTheRightOfTheTitle',
          label: (intl) =>
            intl.formatMessage({
              description: 'to the right of the title label',
              defaultMessage:
                'To the right of the title (Valid only if present)',
            }),
        },
      ],
    },
  },
  subSessionBlockTitleIntervention: {
    key: 'subSessionBlockTitleIntervention',
    label: (intl) =>
      intl.formatMessage({
        description: 'title intervention label',
        defaultMessage: 'Title intervention',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
    },
  },
  subSessionBlockStartEndTime: {
    key: 'subSessionBlockStartEndTime',
    label: (intl) =>
      intl.formatMessage({
        description: 'start - end time label',
        defaultMessage: 'Start - end time',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      position: [
        {
          key: 'toTheRightOfTheTitle',
          label: (intl) =>
            intl.formatMessage({
              description: 'to the right of the title label',
              defaultMessage: 'To the right of the title',
            }),
        },
      ],
    },
  },
  interventionBlockCode: {
    key: 'interventionBlockCode',
    label: (intl) =>
      intl.formatMessage({
        description: 'code intervention label',
        defaultMessage: 'Code intervention',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      position: [
        {
          key: 'underTheModerators',
          label: (intl) =>
            intl.formatMessage({
              description: 'under the moderators label',
              defaultMessage: 'Under the moderators (Valid only if present)',
            }),
        },
      ],
    },
  },
  interventionBlockTitle: {
    key: 'interventionBlockTitle',
    label: (intl) =>
      intl.formatMessage({
        description: 'title intervention label',
        defaultMessage: 'Title intervention',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      // position: [
      //   {
      //     key: 'underTheModerators',
      //     label: (intl) =>
      //       intl.formatMessage({
      //         description: 'under the moderators label',
      //         defaultMessage: 'Under the moderators (Valid only if present)',
      //       }),
      //   },
      // ],
    },
  },
  interventionBlockTypology: {
    key: 'interventionBlockTypology',
    label: (intl) =>
      intl.formatMessage({
        description: 'typology label',
        defaultMessage: 'Typology title',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      // position: [
      //   {
      //     key: 'toTheRightOfTheSpeechDiscussionTitle',
      //     label: (intl) =>
      //       intl.formatMessage({
      //         description:
      //           'to the right of the speech / discussion title label',
      //         defaultMessage:
      //           'To the right of the speech / discussion title (Valid only if present)',
      //       }),
      //   },
      // ],
    },
  },
  interventionBlockDescription: {
    key: 'interventionBlockDescription',
    label: (intl) =>
      intl.formatMessage({
        description: 'intervention description label',
        defaultMessage: 'Intervention description',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
    },
  },
  interventionBlockStartEndTime: {
    key: 'interventionBlockStartEndTime',
    label: (intl) =>
      intl.formatMessage({
        description: 'start - end time label',
        defaultMessage: 'Start - end time',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      position: [
        {
          key: 'toTheRightOfTheSpeechDiscussionTitle',
          label: (intl) =>
            intl.formatMessage({
              description:
                'to the right of the speech / discussion title label',
              defaultMessage: 'To the right of the speech / discussion title',
            }),
        },
      ],
    },
  },
  // interventionBlockEndTime: {
  //   key: 'interventionBlockEndTime',
  //   label: (intl) =>
  //     intl.formatMessage({
  //       description: 'end time label',
  //       defaultMessage: 'End time',
  //     }),
  //   options: {
  //     fontSize: 'fontSize',
  //     format: [
  //       {
  //         key: 'isBold',
  //         label: (intl) =>
  //           intl.formatMessage({
  //             description: 'bold label',
  //             defaultMessage: 'Bold',
  //           }),
  //       },
  //       {
  //         key: 'isItalic',
  //         label: (intl) =>
  //           intl.formatMessage({
  //             description: 'italic label',
  //             defaultMessage: 'Italic',
  //           }),
  //       },
  //       {
  //         key: 'isUnderlined',
  //         label: (intl) =>
  //           intl.formatMessage({
  //             description: 'underlined label',
  //             defaultMessage: 'Underlined',
  //           }),
  //       },
  //     ],
  //   },
  // },
  attendeeRole: {
    key: 'attendeeRole',
    label: (intl) =>
      intl.formatMessage({
        description: 'role label',
        defaultMessage: 'Role',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
    },
  },
  attendeeName: {
    key: 'attendeeName',
    label: (intl) =>
      intl.formatMessage({
        description: 'name label',
        defaultMessage: 'Name',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      position: [
        {
          key: 'nSurname',
          label: (intl) =>
            intl.formatMessage({
              description: 'N.Surname label',
              defaultMessage: 'N.Surname*',
            }),
          note: (intl) =>
            intl.formatMessage({
              description: 'Note: double name text',
              defaultMessage:
                'Note: if a faculty has a double name, the second initial / name must also appear',
            }),
          group: 'attendeeName',
        },
        {
          key: 'nNameSurname',
          label: (intl) =>
            intl.formatMessage({
              description: 'Name Surname label',
              defaultMessage: 'Name Surname',
            }),
          group: 'attendeeName',
          defaultValue: true,
        },
      ],
    },
  },
  abstractAuthors: {
    key: 'abstractAuthors',
    label: (intl) =>
      intl.formatMessage({
        description: 'authors label',
        defaultMessage: 'Authors',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      position: [
        {
          //MUST BE THE SAME OF FACULTY FOR UNIFORM TO FACULTY FLAG
          key: 'nSurname',
          label: (intl) =>
            intl.formatMessage({
              description: 'N.Surname label',
              defaultMessage: 'N.Surname',
            }),
          note: (intl) =>
            intl.formatMessage({
              description: 'Note: double name text',
              defaultMessage:
                'Note: if a faculty has a double name, the second initial / name must also appear',
            }),
          group: 'authorName',
        },
        {
          key: 'nNameSurname',
          label: (intl) =>
            intl.formatMessage({
              description: 'Name Surname label',
              defaultMessage: 'Name Surname',
            }),
          group: 'authorName',
          defaultValue: true,
        },
        {
          key: 'onlyThePresenter',
          label: (intl) =>
            intl.formatMessage({
              description: 'only the presenter label',
              defaultMessage: 'Only the presenter ',
            }),
          group: 'authorsVisibility',
        },
        // {
        //   key: 'uniformToFaculty',
        //   label: (intl) =>
        //     intl.formatMessage({
        //       description: 'uniform to faculty label',
        //       defaultMessage:
        //         'Uniform to Faculty (if checked, consider the Faculty format)',
        //     }),
        // },
        {
          key: 'allAuthors',
          label: (intl) =>
            intl.formatMessage({
              description: 'all authors label',
              defaultMessage: 'All authors',
            }),
          group: 'authorsVisibility',
          defaultValue: true,
        },
        {
          key: 'underlineThePresenter',
          label: (intl) =>
            intl.formatMessage({
              description: 'underline the presenter label',
              defaultMessage: 'Underline the presenter',
            }),
        },
      ],
    },
  },
  abstractAffiliation: {
    key: 'abstractAffiliation',
    label: (intl) =>
      intl.formatMessage({
        description: 'affiliation label',
        defaultMessage: 'Affiliation',
      }),
    options: {
      fontSize: 'fontSize',
      format: [
        {
          key: 'isBold',
          label: (intl) =>
            intl.formatMessage({
              description: 'bold label',
              defaultMessage: 'Bold',
            }),
        },
        {
          key: 'isItalic',
          label: (intl) =>
            intl.formatMessage({
              description: 'italic label',
              defaultMessage: 'Italic',
            }),
        },
        {
          key: 'isUnderlined',
          label: (intl) =>
            intl.formatMessage({
              description: 'underlined label',
              defaultMessage: 'Underlined',
            }),
        },
      ],
      position: [
        {
          key: 'institution',
          label: (intl) =>
            intl.formatMessage({
              description: 'institution label',
              defaultMessage: 'Institution',
            }),
        },
        {
          key: 'city',
          label: (intl) =>
            intl.formatMessage({
              description: 'city label',
              defaultMessage: 'City',
            }),
        },
        // {
        //   key: 'province',
        //   label: (intl) =>
        //     intl.formatMessage({
        //       description: 'province label',
        //       defaultMessage: 'Province',
        //     }),
        // },
        // {
        //   key: 'country',
        //   label: (intl) =>
        //     intl.formatMessage({
        //       description: 'country label',
        //       defaultMessage: 'Country (always)',
        //     }),
        // },
        // {
        //   key: 'countryNotItaly',
        //   label: (intl) =>
        //     intl.formatMessage({
        //       description: 'country (only if not Italy) label',
        //       defaultMessage: 'Country (only if not Italy)',
        //     }),
        // },
        {
          key: 'closingBetweenBrackets',
          label: (intl) =>
            intl.formatMessage({
              description: 'closing between brackets label',
              defaultMessage: 'Closing between brackets "(...)"',
            }),
          defaultValue: true,
        },
        {
          key: 'commaSeparator',
          label: (intl) =>
            intl.formatMessage({
              description: 'comma separator label',
              defaultMessage: 'Comma separator ","',
            }),
          group: 'separator',
          defaultValue: true,
        },
        {
          key: 'pointSeparatorSemicolon',
          label: (intl) =>
            intl.formatMessage({
              description: 'point separator semicolon label',
              defaultMessage: 'Point separator semicolon ";"',
            }),
          group: 'separator',
        },
        {
          key: 'separatorHyphen',
          label: (intl) =>
            intl.formatMessage({
              description: 'separator hyphen label',
              defaultMessage: 'Separator hyphen "-"',
            }),
          group: 'separator',
        },
        {
          key: 'doNotShowAffiliation',
          label: (intl) =>
            intl.formatMessage({
              description: 'do not show affiliation label',
              defaultMessage: 'Do not show affiliation',
            }),
        },
      ],
    },
  },
};

const AgendaViewTypes = {
  DAILY: {
    key: 'daily',
    label: (intl) =>
      intl.formatMessage({
        description: 'daily agenda view type label',
        defaultMessage: 'Daily',
      }),
  },
  MONTHLY: {
    key: 'monthly',
    label: (intl) =>
      intl.formatMessage({
        description: 'monthly agenda view type label',
        defaultMessage: 'Monthly',
      }),
  },
};

const WebsitePages = {
  PARTICIPANT_PROFILE_PAGE: {
    key: 'participant_profile_page',
    label: (intl) =>
      intl.formatMessage({
        description: 'Participant page label',
        defaultMessage: 'Participant page',
      }),
  },
  SPONSOR_PAGE: {
    key: 'sponsor_page',
    label: (intl) =>
      intl.formatMessage({
        description: 'sponsor page label',
        defaultMessage: 'Sponsor page',
      }),
  },
  SPEAKER_PROFILE_PAGE: {
    key: 'speaker_profile_page',
    label: (intl) =>
      intl.formatMessage({
        description: 'speaker page label',
        defaultMessage: 'Speaker page',
      }),
  },
};

const EmailTemplateTypes = {
  AGENCY: {
    key: 'agency',
    label: (intl) =>
      intl.formatMessage({
        description: 'Agency email type label',
        defaultMessage: 'Agency',
      }),
  },
  AGENCY_PURCHASE: {
    key: 'agency_purchase',
    label: (intl) =>
      intl.formatMessage({
        description: 'Agency purchase email type label',
        defaultMessage: 'Agency Purchase',
      }),
  },
  PARTICIPATION: {
    key: 'participation',
    label: (intl) =>
      intl.formatMessage({
        description: 'Participant email type label',
        defaultMessage: 'Participant',
      }),
  },
  SPONSOR: {
    key: 'sponsor',
    label: (intl) =>
      intl.formatMessage({
        description: 'sponsor email type label',
        defaultMessage: 'Sponsor',
      }),
  },
  SPONSOR_PURCHASE: {
    key: 'sponsor_purchase',
    label: (intl) =>
      intl.formatMessage({
        description: 'sponsor purchase email type label',
        defaultMessage: 'Sponsor Purchase',
      }),
  },
  ABSTRACT: {
    key: 'abstract',
    label: (intl) =>
      intl.formatMessage({
        description: 'Abstract email type label',
        defaultMessage: 'Abstract',
      }),
  },
  PRODUCT: {
    key: 'product',
    label: (intl) =>
      intl.formatMessage({
        description: 'participant purchase email type label',
        defaultMessage: 'Participant Purchase',
      }),
  },
  USER: {
    key: 'user',
    isSystemOption: true,
    label: (intl) =>
      intl.formatMessage({
        description: 'user email type label',
        defaultMessage: 'User',
      }),
  },
  GENERIC_PAYMENT: {
    key: 'generic_payment',
    label: (intl) =>
      intl.formatMessage({
        description: 'customized order email type label',
        defaultMessage: 'Customized Order',
      }),
  },
  CONFIRM_GENERIC_PAYMENT: {
    key: 'confirm_generic_payment',
    label: (intl) =>
      intl.formatMessage({
        description: 'confirm payment email type label',
        defaultMessage: 'Confirm payment',
      }),
  },
  GENERIC: {
    key: 'generic',
    label: (intl) =>
      intl.formatMessage({
        description: 'generic email type label',
        defaultMessage: 'Generic',
      }),
  },
};

const WelcomeTempleteTypes = {
  TRAVELGO: {
    key: 'travelGo',
    label: (intl) =>
      intl.formatMessage({
        description: 'Outward travel welcome templete type label',
        defaultMessage: 'Outward travel',
      }),
  },
  TRAVELBACK: {
    key: 'travelBack',
    label: (intl) =>
      intl.formatMessage({
        description: 'Return travel welcome templete type label',
        defaultMessage: 'Return travel',
      }),
  },
  TRANSFER: {
    key: 'transfer',
    isSystemOption: true,
    label: (intl) =>
      intl.formatMessage({
        description: 'Transfer welcome templete type label',
        defaultMessage: 'Transfer',
      }),
  },
};

const EventCategories = {
  1: {
    id: 1,
    name: 'Advisory Board',
  },
  2: {
    id: 2,
    name: 'Corso ECM',
  },
  3: {
    id: 3,
    name: 'Meeting Monosponsor',
  },
  4: {
    id: 4,
    name: 'Delegazione a congresso Italia',
  },
  5: {
    id: 5,
    name: 'Delegazione a congresso estero',
  },
  6: {
    id: 6,
    name: 'Investigator’s Meeting',
  },
  7: {
    id: 7,
    name: 'Convention',
  },
  8: {
    id: 8,
    name: 'Riunione staff/Riunione di ciclo',
  },
  9: {
    id: 9,
    name: 'Speaker’s Meeting',
  },
};

const AgendaTypes = {
  DRAFT: {
    type: 'draft',
    queryField: 'agendaDraft',
    // idPrefix used only for public agenda and speakers
    idPrefix: null,
  },
  SPEAKERS: {
    type: 'speakers',
    queryField: 'agendaSpeakers',
    idPrefix: 'speakers',
  },
  AGENDA: { type: 'agenda', queryField: 'agenda', idPrefix: 'agenda' },
};

const N2NConnectionTypes = {
  PARTICIPATION_ADDITIONAL_SERVICE: 'participation_additional_service',
};

export default {
  EventCategories,
  Clusters,
  Orientations,
  Status,
  Services,
  GatewayServices,
  StandardField,
  StandardFieldAbstract,
  VisaFields,
  AbstractFieldContext,
  FieldControlTypes,
  SponsorServices,
  SponsorServicesSubMenu,
  AgendaSessionTypes,
  AgendaContextTypes,
  EventRoomTypes,
  SponsorBannerPagesTemplate,
  ProductType,
  ProductTypes,
  PaymentTypes,
  PaymentGateway,
  PaymentChildStatuses,
  AgendaSessionLiveReactions,
  OtherStandServiceItemType,
  SponsorServiceItemType,
  EventGroups,
  ParticipantStatus,
  ParticipantState,
  BuyersStatus,
  PaymentStatuses,
  HotelTreatments,
  CancellationStatuses,
  UserTypes,
  EventTypes,
  ParticipationWallItems,
  ModificationRequestTypes,
  ModificationRequestStatus,
  IssueStatus,
  reviewStatus,
  AbstractStatus,
  agendaSessionTypologies,
  agendaSessionStatus,
  agendaSessionManualManagementStatuses,
  AgendaSpeechesTypologies,
  GrantStatuses,
  GrantType,
  AdditionalServicesServiceTypology,
  agendaSessionMode,
  agendaSessionAttendees,
  posterSessionTypologies,
  AgendaSpeechesTitleDescriptions,
  DestinationTravelTypes,
  TravelTypes,
  TicketRequest,
  PapersTypologies,
  BadgesFontFamilies,
  BadgesFontStyles,
  BadgesParagraphs,
  BadgesFieldTypes,
  BadgesBarcodeRotation,
  advancedFiltersFieldTypes,
  Templates,
  currencies,
  TemplateButtonVariants,
  InvoiceToType,
  DocumentType,
  rules,
  countriesIsoCodesJson,
  TwitterPostsStatuses,
  BreakoutRoomStatuses,
  AllotmentChannels,
  StandardFieldDataType,
  timezones,
  EventGenericMenuItems,
  EventEditPersonalDetailsTypes,
  EventHomepageItems,
  EventHomepageActivitiesTypes,
  EventHomepageMapShapes,
  EventHomepageMapLinkTypes,
  AgendaFixedRoles,
  PrintOptions,
  SponsorOtherServicesTypes,
  SponsorServicesType,
  BillingStatuses,
  BillingFlowTypes,
  NotificationsSchemas,
  LinkMatcherConstants,
  TitleOptions,
  PaymentTypesWithTranslations,
  alwaysActiveEmailTemplatesTriggers,
  AgendaViewTypes,
  notEditableEmailTemplatesParams,
  WebsitePages,
  EmailTemplateTypes,
  WelcomeTempleteTypes,
  AbstractMotivations,
  FacultyResponseStatus,
  FacultyPageStatus,
  AgendaTypes,
  ConflictStatuses,
  N2NConnectionTypes,
  GroupStatuses,
  participantStatuses,
};
